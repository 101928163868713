import { EditIcon } from "@/assets";
import {
  formatMasked,
  handleWebTransition,
  routes,
  TOTPActions,
} from "@/utils";
import { Button, Switch } from "@hyperlocal/vital2";
import { Layout } from "@newCard/components";
import { NewCardFormSchema } from "@newCard/utils";
import { Label } from "@radix-ui/react-label";
import { useFormContext } from "react-hook-form";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const styles = tv({
  slots: {
    dt: "font-base text-xs/4 font-medium text-neutral-darkest mobile:text-x3s",
    dd: "font-base text-xs/5 font-medium text-neutral-dark overflow-hidden text-ellipsis whitespace-nowrap mobile:text-x2s",
    containerTitle:
      "flex gap-inset-md font-base text-base font-bold text-neutral-darkest",
    dl: "flex flex-col gap-2 mobile:flex-row tablet:flex-row mobile:justify-between tablet:justify-between",
  },
});

export const CardResume = () => {
  const { containerTitle, dd, dt, dl } = styles();

  const { getValues } = useFormContext<NewCardFormSchema>();

  const { cardholder, deliveryAddress, cardInfo } = getValues();

  const location = useLocation();

  const navigate = useNavigate();

  const handleNavigation = (to: To, options?: NavigateOptions) => {
    handleWebTransition(() => navigate(to, options));
  };

  const previousRoute =
    location.state?.from || routes.newCardAddress + location.search;

  const cardTypeTitle =
    cardInfo.type === "physical" ? "cartão físico" : "cartão virtual";

  return (
    <Layout.Root>
      <Layout.Link to={previousRoute}>Novo {cardTypeTitle}</Layout.Link>
      <Layout.Title>Resumo do {cardTypeTitle}</Layout.Title>
      <Layout.Content>
        <div>
          <h6 className={containerTitle()}>
            Dados do titular do cartão
            <button
              onClick={() =>
                handleNavigation({
                  pathname: routes.newCardholder,
                  search: location.search,
                })
              }
            >
              <EditIcon />
            </button>
          </h6>
          <div className="mt-inset-md grid grid-cols-2 gap-inset-md mobile:grid-cols-1 tablet:grid-cols-1">
            <dl className={dl()}>
              <dt className={dt()}>Titular do cartão</dt>
              <dd className={dd()}>{cardholder.name || "Vitor Nishida"}</dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>CPF</dt>
              <dd className={dd()}>
                {formatMasked(cardholder.document, "###------##") ||
                  "060-----15"}
              </dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>Celular</dt>
              <dd className={dd()}>
                {formatMasked(cardholder.cellphone, "(##) #####-####") ||
                  "(11) 98724 -3123"}
              </dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>E-mail</dt>
              <dd className={dd()}>
                {cardholder.email || "hyperlocalteste@teste.com"}
              </dd>
            </dl>
          </div>
        </div>
        {cardInfo.type === "physical" && (
          <div>
            <hr className="my-inset-md" />
            <h6 className={twMerge(containerTitle(), "mb-inset-md")}>
              Endereço de entrega
              <button
                onClick={() =>
                  handleNavigation(
                    {
                      pathname: routes.newCardAddress,
                      search: location.search,
                    },
                    {
                      state: {
                        from: routes.newCardResume + location.search,
                        to: routes.newCardResume + location.search,
                      },
                    },
                  )
                }
              >
                <EditIcon />
              </button>
            </h6>
            <div className="grid grid-rows-3 gap-inset-md mobile:grid-rows-2 tablet:grid-rows-2">
              <div className="grid grid-cols-3 gap-inset-md mobile:grid-cols-1 tablet:grid-cols-1">
                <dl className={dl()}>
                  <dt className={dt()}>CEP</dt>
                  <dd className={dd()}>
                    {formatMasked(deliveryAddress.zipcode, "#####-###") ||
                      "00000 - 000"}
                  </dd>
                </dl>
                <dl className={dl()}>
                  <dt className={dt()}>Endereço</dt>
                  <dd className={dd()}>
                    {deliveryAddress.address || "Rua Bela Cintra"}
                  </dd>
                </dl>
                <dl className={dl()}>
                  <dt className={dt()}>Número</dt>
                  <dd className={dd()}>{deliveryAddress.number || "1114"}</dd>
                </dl>
              </div>
              <div className="row-span-2 grid grid-cols-2 gap-inset-md mobile:row-auto mobile:grid-cols-1 tablet:row-auto tablet:grid-cols-1">
                <dl className={dl()}>
                  <dt className={dt()}>Cidade</dt>
                  <dd className={dd()}>
                    {deliveryAddress.city || "São Paulo"}
                  </dd>
                </dl>
                <dl className={dl()}>
                  <dt className={dt()}>Estado</dt>
                  <dd className={dd()}>{deliveryAddress.state || "SP"}</dd>
                </dl>
                <dl className={dl()}>
                  <dt className={dt()}>Bairro</dt>
                  <dd className={dd()}>
                    {deliveryAddress.neighborhood || "Paulista"}
                  </dd>
                </dl>
                {deliveryAddress.complement && (
                  <dl className={dl()}>
                    <dt className={dt()}>Complemento</dt>
                    <dd className={dd()}>
                      {deliveryAddress.complement || "Ao lado do hotel Ibis"}
                    </dd>
                  </dl>
                )}
              </div>
              <hr />
              <div className="flex gap-inset-x2s">
                <Switch id="cardLimit" />
                <Label
                  htmlFor="cardLimit"
                  className="font-base text-base text-neutral-darkest"
                >
                  Cartão possui limite
                </Label>
              </div>
            </div>
          </div>
        )}
        <Button.Root
          className="mt-auto mobile:mt-16 tablet:mt-16"
          onClick={() =>
            handleNavigation(
              {
                pathname: routes.newCardConfirmTOTP,
                search: location.search,
              },
              {
                state: {
                  TOTPAction:
                    cardInfo.type === "physical"
                      ? TOTPActions.createPhysicalCard
                      : TOTPActions.createVirtualCard,
                },
              },
            )
          }
        >
          Pedir cartão
        </Button.Root>
      </Layout.Content>
    </Layout.Root>
  );
};
