import { ComponentProps } from "react";

export function TrashIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.3 20.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 015.5 18.7V6h-.25a.726.726 0 01-.75-.75.726.726 0 01.75-.75H9c0-.25.087-.458.262-.625a.892.892 0 01.638-.25h4.2c.25 0 .463.083.638.25A.828.828 0 0115 4.5h3.75a.728.728 0 01.75.75.728.728 0 01-.75.75h-.25v12.7c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H7.3zM7 6v12.7c0 .083.03.154.088.213A.289.289 0 007.3 19h9.4a.292.292 0 00.213-.087A.292.292 0 0017 18.7V6H7zm2.4 10.25a.728.728 0 00.75.75c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538v-7.5a.731.731 0 00-.212-.538A.731.731 0 0010.15 8a.728.728 0 00-.75.75v7.5zm3.7 0c0 .217.07.396.212.538a.731.731 0 00.538.212c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538v-7.5a.731.731 0 00-.212-.538A.731.731 0 0013.85 8a.731.731 0 00-.538.212.731.731 0 00-.212.538v7.5zM7 6v12.7c0 .083.03.154.088.213A.289.289 0 007.3 19H7V6z"
        fill="#0047F6"
      />
    </svg>
  );
}
