import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import { z } from "zod";

export const cardholderSchema = z.object({
  name: z.string().min(1, "Nome do títular é obrigatório"),
  document: z.custom<string>(
    (document: string) => isValidCPF(document),
    "CPF inválido",
  ),
  cellphone: z.string().min(11, "Celular é obrigatório"),
  email: z.string().email("E-mail inválido").min(1, "E-mail é obrigatório"),
});

export type CardholderSchema = z.infer<typeof cardholderSchema>;
