import { toast } from "@hyperlocal/vital2";
import {
  QueryCache,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta.errorMessage) {
        const message = (query.meta.errorMessage as string) || "Houve um erro.";
        toast({
          title: message,
          variant: "error",
        });
      }
      return error;
    },
  }),
});

export type RequestError = AxiosError<unknown>;

export type QueryConfig<FetcherFnType extends (...args: unknown[]) => unknown> =
  UseQueryOptions<Awaited<ReturnType<FetcherFnType>>>;

export type MutationConfig<
  FetcherFnType extends (...args: unknown[]) => unknown,
> = UseMutationOptions<
  Awaited<ReturnType<FetcherFnType>>,
  RequestError,
  Parameters<FetcherFnType>[0]
>;
