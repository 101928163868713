import cardsMock from "@/tests/mocks/cards.json";
import { CardsResponse } from "@cards/types";
import { UseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";

type CardsFiltered = {
  virtual: CardsResponse[];
  temporary: CardsResponse[];
  physical: CardsResponse[];
};

const cards = cardsMock.cards as CardsResponse[];

const fetchMock = () => {
  return new Promise<CardsResponse[]>((resolve) => {
    setTimeout(() => {
      resolve(cards);
    }, 1000);
  });
};

const getCards = async () => {
  try {
    const response = await fetchMock();

    return response.length > 0
      ? response.reduce<CardsFiltered>(
          (prev, cur) => {
            if (cur.type === "physical") prev.physical.push(cur);
            if (cur.type === "virtual") prev.virtual.push(cur);
            if (cur.type === "temporary") prev.temporary.push(cur);

            return prev;
          },
          {
            virtual: [],
            physical: [],
            temporary: [],
          },
        )
      : [];
  } catch (error) {
    throw new Error(error);
  }
};

const useCards = (options?: UseQueryOptions<CardsFiltered>) => {
  return useSuspenseQuery({
    ...options,
    queryKey: ["getCards"],
    queryFn: () => getCards(),
  });
};

export const useCardsList = () => {
  const { data, isLoading, isError, refetch } = useCards();

  const emptyCards = !data.physical && !data.temporary && !data.virtual;

  return { data, isLoading, isError, emptyCards, refetch };
};
