import { ComponentProps } from "react";
import { Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

interface ErrorFeedbackProps extends ComponentProps<"div"> {
  onRetry: () => void;
}

export const ErrorFeedback = ({
  onRetry,
  className,
  ...rest
}: ErrorFeedbackProps) => {
  return (
    <div
      className={twMerge(
        "flex h-full flex-1 flex-col items-center justify-center gap-stack-sm",
        className,
      )}
      {...rest}
    >
      <h5 className="text-center font-base font-medium" role="alert">
        Houve um problema ao buscar as informações. <br /> Por favor, tente
        novamente.
      </h5>
      <Button.Root onClick={onRetry}>Tentar novamente</Button.Root>
    </div>
  );
};
