import { useState } from "react";
import { CheckedStatusIcon } from "@/assets";
import { handleWebTransition, routes, TOTPActions } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Button } from "@hyperlocal/vital2";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPinRules, HelperTooltip, Input, liStyles, rules } from "./helper";

type ChangePasswordForm = {
  newPassword: string;
  confirmNewPassword: string;
};

export const ChangePin = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [formState, setFormState] = useState<ChangePasswordForm>({
    newPassword: "",
    confirmNewPassword: "",
  });

  const onClose = () => {
    handleWebTransition(() => navigate({ pathname: routes.cards, search }));
  };

  const isPinRulesChecked = checkPinRules({
    pin: formState.newPassword,
    pinConfirmation: formState.confirmNewPassword,
  });

  const handleChangeInput = ({
    e,
    input,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    input: keyof ChangePasswordForm;
  }) => {
    const regex = /^[0-9]*$/;

    if (regex.test(e.target.value))
      setFormState((prev) => ({
        ...prev,
        [input]: e.target.value,
      }));
  };

  const handleChangePin = () => {
    handleWebTransition(() =>
      navigate(
        { pathname: routes.confirmTOTP, search },
        {
          state: {
            TOTPAction: TOTPActions.changeCardPin,
          },
        },
      ),
    );
  };

  const areRulesChecked = Object.values(
    checkPinRules({
      pin: formState.newPassword,
      pinConfirmation: formState.confirmNewPassword,
    }),
  ).every((boolean) => boolean);

  return (
    <div className="flex h-full flex-col mobile:min-h-[100vh] mobile:p-4 mobile:pb-8 tablet:min-h-[100vh] tablet:p-4 tablet:pb-8">
      <h4 className="mb-8 flex justify-between font-base text-2xl font-bold text-neutral-darkest mobile:text-sm/5 tablet:text-sm/5">
        Alterar senha
        <button className="desktop:hidden" onClick={onClose}>
          <Icon
            name="GeralClose"
            fill="currentColor"
            width={24}
            className="text-neutral-dark"
          />
        </button>
      </h4>
      <form className="flex h-full flex-1 flex-col gap-6">
        <Input
          label="Nova senha"
          onChange={(e) => handleChangeInput({ e, input: "newPassword" })}
          value={formState.newPassword}
        />
        <Input
          label="Confirmar nova senha"
          onChange={(e) =>
            handleChangeInput({ e, input: "confirmNewPassword" })
          }
          value={formState.confirmNewPassword}
        />
        <ul className="mt-inset-lg flex flex-col gap-2">
          {rules.map((item) => {
            const dataAttributes = {
              [`data-${item.id.toLocaleLowerCase()}`]:
                isPinRulesChecked[item.id],
            };

            return (
              <li
                key={item.label}
                className={liStyles({
                  fullfilled: isPinRulesChecked[item.id],
                })}
                {...dataAttributes}
              >
                {isPinRulesChecked[item.id] ? (
                  <CheckedStatusIcon />
                ) : (
                  <div className="size-6 rounded-circle border" />
                )}

                {item.label}
                {item.helper && <HelperTooltip message={item.helper} />}
              </li>
            );
          })}
        </ul>
        <div className="mt-auto flex gap-6">
          <Button.Root variant="link" fullWidth type="button">
            Cancelar
          </Button.Root>
          <Button.Root
            disabled={!areRulesChecked}
            fullWidth
            onClick={handleChangePin}
            type="button"
          >
            Trocar senha
          </Button.Root>
        </div>
      </form>
    </div>
  );
};
