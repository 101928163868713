import "./tailwind.css";
import { Suspense } from "react";
import { LoadingFallback } from "@/components";
import { FormProvider } from "@/features/newCard/Providers/FormProvider";
import { NewCardNavigation } from "@/features/newCard/Providers/NewCardNavigation";
import { queryClient } from "@/lib";
import { Routes } from "@/routes";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";

export function App() {
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingFallback />}>
        <QueryClientProvider client={queryClient}>
          <FormProvider>
            <NewCardNavigation>
              {process.env.NODE_ENV === "development" && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
              <Routes />

              <Toaster position={isMobile ? "bottom-center" : "top-right"} />
            </NewCardNavigation>
          </FormProvider>
        </QueryClientProvider>
      </Suspense>
    </BrowserRouter>
  );
}
