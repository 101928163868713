import { routes } from "@/utils";

export const newCardPaths = [
  routes.newCardAddress,
  routes.newCardResume,
  routes.newCardholder,
  routes.newCardNickname,
];

export const CARD_PIN_MIN_LENGTH = 4;

export const MASKED_INPUT_DEFAULT_PROPS = {
  autoUnmask: true,
  placeholder: "",
  showMaskOnFocus: false,
  showMaskOnHover: false,
};

export const FORM_TYPE = {
  deliveryAddress: "deliveryAddress",
  cardholder: "cardholder",
  cardInfo: "cardInfo",
} as const;

export const CARD_TYPE = {
  physical: "physical",
  virtual: "virtual",
  temporary: "temporary",
  select: "select",
} as const;

export const CEP_LENGTH = 8;
