export const Spinner = () => {
  return (
    <svg
      className="h-[100px] w-[100px] animate-rotate"
      role="progressbar"
      viewBox="0 0 50 50"
    >
      <circle
        className="animate-dash stroke-neutral-dark"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      ></circle>
    </svg>
  );
};
