import { ComponentProps } from "react";
import { routes, TOTPActions, useMediaQuery } from "@/utils";
import { Button, Dialog, Sheet } from "@hyperlocal/vital2";
import { useLocation, useNavigate } from "react-router-dom";

export const CancelCardAction = (
  props: ComponentProps<typeof Dialog.Trigger>,
) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const isMobile = useMediaQuery("mobile");

  return (
    <>
      {!isMobile && (
        <div className="mobile:hidden tablet:hidden">
          <Dialog.Root>
            <Dialog.Trigger asChild {...props} />
            <Dialog.Content className="max-w-[370px]">
              <Dialog.Header>
                <Dialog.Title>Cancelar cartão</Dialog.Title>
              </Dialog.Header>
              <p>
                Tem certeza que deseja cancelar cartão? <br /> Você não poderá
                mais utilizar este cartão, e todas as recorrências vinculadas ao
                cartão serão canceladas.
              </p>
              <Dialog.Footer>
                <Dialog.Close asChild>
                  <Button.Root fullWidth variant="secondary">
                    Não
                  </Button.Root>
                </Dialog.Close>
                <Dialog.Close
                  asChild
                  onClick={() =>
                    navigate(
                      { pathname: routes.newCardConfirmTOTP, search },
                      {
                        state: {
                          TOTPAction: TOTPActions.cancelCard,
                        },
                      },
                    )
                  }
                >
                  <Button.Root fullWidth variant="primary">
                    Sim
                  </Button.Root>
                </Dialog.Close>
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div className="desktop:hidden">
          <Sheet.Root>
            <Sheet.Trigger asChild {...props} />
            <Sheet.Content side="bottom">
              <Sheet.Header>
                <Sheet.Title>Cancelar cartão</Sheet.Title>
              </Sheet.Header>
              <p>
                Tem certeza que deseja cancelar cartão? <br /> Você não poderá
                mais utilizar este cartão, e todas as recorrências vinculadas ao
                cartão serão canceladas.
              </p>
              <Sheet.Footer className="flex flex-col gap-2">
                <Sheet.Close asChild>
                  <Button.Root fullWidth variant="secondary">
                    Não
                  </Button.Root>
                </Sheet.Close>
                <Sheet.Close
                  asChild
                  onClick={() =>
                    navigate(
                      { pathname: routes.newCardConfirmTOTP, search },
                      {
                        state: {
                          TOTPAction: TOTPActions.cancelCard,
                        },
                      },
                    )
                  }
                >
                  <Button.Root fullWidth variant="primary">
                    Sim
                  </Button.Root>
                </Sheet.Close>
              </Sheet.Footer>
            </Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};
