import { ComponentProps } from "react";

export function PayTransferIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.394 14.514a.414.414 0 00.314-.13.432.432 0 00.123-.307v-2.565l.773.788c.078.078.18.117.307.117a.414.414 0 00.306-.117.455.455 0 00.117-.313.405.405 0 00-.117-.3l-1.444-1.443a.534.534 0 00-.182-.125.55.55 0 00-.394 0 .41.41 0 00-.168.125l-1.458 1.443a.405.405 0 00-.117.3c0 .121.04.225.117.313.078.078.18.117.306.117a.414.414 0 00.307-.117l.773-.788v2.565c0 .117.04.219.124.306a.412.412 0 00.313.132zM7.667 10.833h-.833a.484.484 0 01-.5-.5.486.486 0 01.5-.5H9v-1H6.917a.565.565 0 01-.417-.166.565.565 0 01-.166-.417V6.417a.565.565 0 01.583-.583h.75v-.167a.484.484 0 01.5-.5.486.486 0 01.5.5v.166H9.5a.487.487 0 01.5.5.485.485 0 01-.5.5H7.334v1h2.083c.167 0 .305.056.417.167.11.111.166.25.166.417v1.833a.565.565 0 01-.166.417.565.565 0 01-.417.166h-.75V11a.487.487 0 01-.141.359.487.487 0 01-.36.14.485.485 0 01-.5-.5v-.166z"
        fill="#1A1A1A"
      />
      <path
        d="M13.35 4.717V7.75a.5.5 0 101 0V4.717c0-.334-.116-.617-.35-.85a1.157 1.157 0 00-.85-.35H2.884c-.334 0-.617.116-.85.35-.234.233-.35.516-.35.85v7.6c0 .333.116.616.35.85.233.233.516.35.85.35H8.85a.487.487 0 00.359-.142.488.488 0 00.141-.358.504.504 0 00-.133-.36.459.459 0 00-.35-.14H2.884a.21.21 0 01-.142-.05c-.039-.034-.058-.084-.058-.15v-7.6c0-.056.02-.103.058-.142a.191.191 0 01.142-.058H13.15c.056 0 .103.02.142.058a.193.193 0 01.058.142z"
        fill="#1A1A1A"
      />
    </svg>
  );
}
