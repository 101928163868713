import { useMemo } from "react";
import EmptyListImg from "@/assets/EmptyList.png";
import { ErrorFeedback } from "@/components";
import { useCardDetail } from "@cards/api";
import { useSearchParams } from "react-router-dom";
import {
  CardDetailLoading,
  CardInRouteDetail,
  GeneralCardDetail,
  TemporaryCardDetail,
} from "./components";

export const CardDetails = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data, isLoading, isError, refetch } = useCardDetail(id);

  const Component = useMemo(() => {
    if (!data) return null;

    if (data.type === "temporary") return <TemporaryCardDetail />;

    if (data.status === "inRoute") return <CardInRouteDetail />;

    return <GeneralCardDetail {...data} />;
  }, [data]);

  if (!id) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <img src={EmptyListImg} alt="Empty list" width={146} />
        <span className="max-w-[283px] text-center">
          Aqui está vazio! Selecione um cartão para ver suas informações.
        </span>
      </div>
    );
  }

  if (isLoading) return <CardDetailLoading />;

  if (isError) return <ErrorFeedback onRetry={refetch} />;

  return Component;
};
