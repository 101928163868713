import { ComponentProps } from "react";

export function LockOpenIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.3 21.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 19.7v-9.4c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525H15v-2c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0012 3.5c-.717 0-1.346.225-1.887.675A2.977 2.977 0 009.075 5.85a.967.967 0 01-.275.462.67.67 0 01-.475.188c-.25 0-.446-.083-.587-.25a.65.65 0 01-.163-.55c.2-1.067.708-1.95 1.525-2.65S10.883 2 12 2c1.25 0 2.313.437 3.188 1.312S16.5 5.25 16.5 6.5v2h1.2c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v9.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3zm0-1.5h11.4a.292.292 0 00.213-.087A.292.292 0 0018 19.7v-9.4a.292.292 0 00-.087-.213A.292.292 0 0017.7 10H6.3a.289.289 0 00-.212.087A.29.29 0 006 10.3v9.4c0 .083.03.154.088.213A.289.289 0 006.3 20zm5.7-3.25c.483 0 .896-.17 1.238-.512.341-.342.512-.755.512-1.238s-.17-.896-.512-1.238A1.689 1.689 0 0012 13.25c-.483 0-.896.17-1.238.512A1.689 1.689 0 0010.25 15c0 .483.17.896.512 1.238.342.341.755.512 1.238.512z"
        fill="#0047F6"
      />
    </svg>
  );
}
