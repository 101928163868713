export const TOTPActions = {
  createPhysicalCard: "createPhysicalCard",
  createTemporaryCard: "createTemporaryCard",
  createVirtualCard: "createVirtualCard",
  blockCard: "blockCard",
  unblockCard: "unblockCard",
  cancelCard: "cancelCard",
  cancelOrder: "cancelOrder",
  confirmCardDelivery: "confirmCardDelivery",
  changeCardPin: "changeCardPin",
} as const;
