import { ComponentProps } from "react";
import { HyperlocalBigCard } from "@/assets";
import CreditCardChip from "@/assets/CreditCardChip.png";
import { ScrollArea } from "@/components";
import { useTooltipTimer } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Button, Progress, Tooltip } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import { CancelCardAction } from "../CancelCardAction";

const CopyIcon = ({ className, ...rest }: ComponentProps<"svg">) => {
  const [isOpen, handleClick] = useTooltipTimer();

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isOpen}>
        <Tooltip.Trigger onClick={handleClick}>
          <Icon
            {...rest}
            name="GeralGeralCopy"
            fill="currentColor"
            className={twMerge("size-4 mobile:size-6 tablet:size-6", className)}
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>Número copiado!</Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const styles = tv({
  slots: {
    dl: "flex flex-col gap-1 w-full",
    dt: "font-base font-medium text-xs/4 text-neutral-darkest",
    dd: "font-base text-base text-neutral-dark mobile:text-x2s tablet:text-x2s whitespace-nowrap text-ellipsis overflow-hidden",
  },
});

export const TemporaryCardDetail = () => {
  const { dd, dl, dt } = styles();

  return (
    <div
      className="flex h-full w-full flex-col"
      data-testid="temporary-card-container"
    >
      <ScrollArea className="h-full">
        <div className="flex h-full flex-col items-center justify-center overflow-hidden p-6">
          <div className="flex flex-col">
            <div className="flex h-full w-full justify-center gap-0">
              <div className="relative flex h-fit items-start">
                <HyperlocalBigCard className="fill-secondary-lighter" />
                <img
                  src={CreditCardChip}
                  alt="Credit card chip"
                  className={twMerge(
                    "absolute left-0 right-0 top-6 ml-auto mr-auto",
                  )}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-center gap-1">
              <span className="w-fit font-base text-x2s/5 text-neutral-darkest">
                Restam
              </span>
              <span className="w-fit font-base text-x2s/5 text-primary-main">
                20h e 30min:
              </span>
            </div>
            <Progress value={40} className="mb-4 mt-1 h-1 w-full" />
          </div>
          <span className="font-base text-xs/5 text-neutral-darkest">
            Expira em
          </span>
          <h5 className="font-base text-sm/7 font-bold text-neutral-darkest">
            20 de Janeiro às 10:30
          </h5>
          <hr className="my-4 h-[1px] w-full bg-neutral-light" />
          <h5 className="self-start font-base text-sm/[30px] font-bold text-neutral-darkest mobile:text-base tablet:text-base">
            Informações do cartão
          </h5>
          <div className="mt-4 grid w-full grid-cols-2 gap-4">
            <dl className={twMerge(dl(), "col-start-1 col-end-3")}>
              <dt className={dt()}>Nome no cliente</dt>
              <dd className={dd()}>Paulo S Ferreira Bistro Paris</dd>
            </dl>
            <dl className={twMerge(dl(), "col-start-1 col-end-3")}>
              <dt className={dt()}>Número</dt>
              <dd className={twMerge(dd(), "flex items-center gap-1")}>
                XXXX XXXX XXXX 4559
                <CopyIcon className="size-6" />
              </dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>Validade</dt>
              <dd className={dd()}>20/23</dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>CVV</dt>
              <dd className={dd()}>123</dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>Tipo</dt>
              <dd className={dd()}>Mastercard Gold</dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>Função</dt>
              <dd className={dd()}>Débito e crédito</dd>
            </dl>
          </div>
        </div>
      </ScrollArea>
      <div className="p-6">
        <CancelCardAction>
          <Button.Root
            fullWidth
            variant="link"
            className="text-status-error-dark hover:text-status-error-dark focus:text-status-error-dark active:text-status-error-dark"
          >
            Cancelar cartão
          </Button.Root>
        </CancelCardAction>
      </div>
    </div>
  );
};
