import { LockOpenIcon } from "@/assets";
import { routes, TOTPActions, useMediaQuery } from "@/utils";
import { Button, Dialog, Sheet } from "@hyperlocal/vital2";
import { useLocation, useNavigate } from "react-router-dom";

export const UnblockCardAction = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const isMobile = useMediaQuery("mobile");

  return (
    <>
      {!isMobile && (
        <div className="mobile:hidden tablet:hidden">
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button.Root
                variant="secondary"
                className="rounded-full px-3 py-3"
                data-testid="unblock-desktop-trigger"
              >
                <LockOpenIcon />
              </Button.Root>
            </Dialog.Trigger>
            <Dialog.Content className="max-w-[370px]">
              <Dialog.Header>
                <Dialog.Title>Desbloquear cartão</Dialog.Title>
              </Dialog.Header>
              <p>
                Tem certeza que deseja desbloquear cartão? O cartão será
                reabilitado para que você possa realizar compras com ele.
              </p>
              <Dialog.Footer>
                <Dialog.Close asChild>
                  <Button.Root fullWidth variant="secondary">
                    Não
                  </Button.Root>
                </Dialog.Close>
                <Dialog.Close
                  asChild
                  onClick={() =>
                    navigate(
                      { pathname: routes.newCardConfirmTOTP, search },
                      {
                        state: {
                          TOTPAction: TOTPActions.unblockCard,
                        },
                      },
                    )
                  }
                >
                  <Button.Root fullWidth variant="primary">
                    Sim
                  </Button.Root>
                </Dialog.Close>
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div className="desktop:hidden">
          <Sheet.Root>
            <Sheet.Trigger asChild>
              <Button.Root
                variant="secondary"
                className="rounded-full px-3 py-3"
                data-testid="unblock-mobile-trigger"
              >
                <Button.Slot name="GeralSecurityLock" />
              </Button.Root>
            </Sheet.Trigger>
            <Sheet.Content side="bottom">
              <Sheet.Header>
                <Sheet.Title>Desbloquear cartão</Sheet.Title>
              </Sheet.Header>
              <p>
                Tem certeza que deseja desbloquear cartão? O cartão será
                reabilitado para que você possa realizar compras com ele.
              </p>
              <Sheet.Footer className="flex flex-col gap-2">
                <Sheet.Close asChild>
                  <Button.Root fullWidth variant="secondary">
                    Não
                  </Button.Root>
                </Sheet.Close>
                <Sheet.Close
                  asChild
                  onClick={() =>
                    navigate(
                      { pathname: routes.newCardConfirmTOTP, search },
                      {
                        state: {
                          TOTPAction: TOTPActions.unblockCard,
                        },
                      },
                    )
                  }
                >
                  <Button.Root fullWidth variant="primary">
                    Sim
                  </Button.Root>
                </Sheet.Close>
              </Sheet.Footer>
            </Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};
