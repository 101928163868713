import {
  ComponentPropsWithoutRef,
  ComponentRef,
  ElementRef,
  forwardRef,
} from "react";
import { handleWebTransition } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { To, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const LayoutRoot = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "mx-auto w-full max-w-[1248px] flex-1 desktop:my-inset-md desktop:px-16",
      className,
    )}
    {...rest}
  />
));
LayoutRoot.displayName = "LayoutRoot";

const LayoutHeader = forwardRef<
  ComponentRef<"header">,
  ComponentPropsWithoutRef<"header">
>(({ className, ...rest }, ref) => (
  <header ref={ref} className={twMerge(className)} {...rest} />
));
LayoutHeader.displayName = "LayoutHeader";

const LayoutColumn = forwardRef<
  ComponentRef<"section">,
  ComponentPropsWithoutRef<"section">
>(({ className, ...rest }, ref) => (
  <section ref={ref} className={twMerge("rounded-sm", className)} {...rest} />
));
LayoutColumn.displayName = "LayoutColumn";

interface LayoutLinkProps extends ComponentPropsWithoutRef<"h3"> {
  to?: To;
}

const LayoutLink = forwardRef<ElementRef<"h3">, LayoutLinkProps>(
  ({ className, children, to, ...rest }, ref) => {
    const navigate = useNavigate();

    const handleNavigate = () => handleWebTransition(() => navigate(to));

    return (
      <h3
        className={twMerge(
          "my-6 flex items-center gap-2 text-lg/8 font-bold text-neutral-darkest mobile:my-0 mobile:mb-1 mobile:px-inset-md mobile:py-inset-md mobile:pb-inset-md mobile:text-sm/5 mobile:shadow-lower tablet:my-0 tablet:mb-1 tablet:px-inset-md tablet:py-inset-md tablet:pb-inset-md tablet:text-sm/5 tablet:shadow-lower",
          className,
        )}
        ref={ref}
        {...rest}
      >
        {to && (
          <button onClick={handleNavigate}>
            <Icon
              name="ArrowNoLineLeft"
              fill="currentColor"
              className="size-inset-md"
            />
          </button>
        )}
        {children}
      </h3>
    );
  },
);
LayoutLink.displayName = "LayoutLink";

const LayoutContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "relative grid grid-cols-2 gap-4 overflow-hidden mobile:grid-cols-1 tablet:grid-cols-1",
      className,
    )}
    {...rest}
  />
));
LayoutContent.displayName = "LayoutContent";

export const Layout = {
  Root: LayoutRoot,
  Header: LayoutHeader,
  Column: LayoutColumn,
  Title: LayoutLink,
  Content: LayoutContent,
};
