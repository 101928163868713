import { ConfirmMFA } from "@/components";
import { routes, setHeader, useMediaQuery } from "@/utils";
import {
  ChangePin,
  ConfirmCardDelivery,
} from "@cards/components/CardDetails/components";
import { CardsDashboard } from "@cards/Pages";
import {
  CardholderData,
  CardNickname,
  CardResume,
  DeliveryAddress,
} from "@newCard/components";
import { Route, Routes as Switch } from "react-router-dom";

const MobileRoutes = () => {
  return (
    <Switch>
      <Route element={<CardsDashboard />} path={routes.cards} />
      <Route element={<DeliveryAddress />} path={routes.newCardAddress} />
      <Route element={<CardResume />} path={routes.newCardResume} />
      <Route element={<CardholderData />} path={routes.newCardholder} />
      <Route element={<ConfirmMFA />} path={routes.newCardConfirmTOTP} />
      <Route element={<CardNickname />} path={routes.newCardNickname} />
      <Route
        element={<ConfirmCardDelivery />}
        path={routes.confirmCardDelivery}
      />
      <Route element={<ChangePin />} path={routes.cardNewPassword} />
      <Route element={<ConfirmMFA />} path={routes.confirmTOTP} />
    </Switch>
  );
};

const DesktopRoutes = () => {
  return (
    <Switch>
      <Route element={<CardsDashboard />} path={routes.cards}>
        <Route element={<DeliveryAddress />} path={routes.newCardAddress} />
        <Route element={<CardResume />} path={routes.newCardResume} />
        <Route element={<CardholderData />} path={routes.newCardholder} />
        <Route element={<ConfirmMFA />} path={routes.newCardConfirmTOTP} />
        <Route element={<CardNickname />} path={routes.newCardNickname} />
        <Route
          element={<ConfirmCardDelivery />}
          path={routes.confirmCardDelivery}
        />
        <Route element={<ChangePin />} path={routes.cardNewPassword} />
        <Route element={<ConfirmMFA />} path={routes.confirmTOTP} />
      </Route>
    </Switch>
  );
};

export const Routes = () => {
  const isMobile = useMediaQuery("mobile");

  setHeader({ show: !isMobile });

  return (
    <div className="flex min-h-[100vh] flex-1 flex-col">
      {isMobile ? <MobileRoutes /> : <DesktopRoutes />}
    </div>
  );
};
