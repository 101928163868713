import { Outlet } from "@/Providers/OutletProvider";
import { routes, useMediaQuery } from "@/utils";
import { CardDetails, CardsList, Layout } from "@cards/components";
import { Breadcrumb } from "@hyperlocal/vital2";
import { Link, useSearchParams } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { twMerge } from "tailwind-merge";

const getLayoutTitleOptions = ({
  isDetailMobile = false,
  isMobile = false,
}) => {
  const titleOptionsMap = {
    desktop: {
      title: "Meus cartões",
      route: routes.dashboard,
    },
    mobile: {
      title: isDetailMobile ? "Detalhes" : "Meus cartões",
      route: isDetailMobile ? routes.cards : routes.dashboard,
    },
  };

  return isMobile ? titleOptionsMap.mobile : titleOptionsMap.desktop;
};

export const CardsDashboard = () => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const isMobile = useMediaQuery("mobile");

  const isDetailMobile = isMobile && !!id;

  const titleOptions = getLayoutTitleOptions({ isDetailMobile, isMobile });

  return (
    <div className="flex h-full w-full flex-1 flex-col bg-neutral-lighter mobile:max-h-[100vh] mobile:bg-neutral-white tablet:max-h-[100vh] tablet:bg-neutral-white">
      <Layout.Root className="mobile:p-0 tablet:p-0">
        <Layout.Header>
          <Breadcrumb.Root className="mobile:hidden tablet:hidden">
            <Breadcrumb.List>
              <Breadcrumb.ListItem>
                <Link to={routes.dashboard}>
                  <Breadcrumb.Home />
                </Link>
              </Breadcrumb.ListItem>
              <Breadcrumb.ListItem>
                <Breadcrumb.Separator />
                <Breadcrumb.Anchor active>Meus cartões</Breadcrumb.Anchor>
              </Breadcrumb.ListItem>
            </Breadcrumb.List>
          </Breadcrumb.Root>
        </Layout.Header>
        <Layout.Title to={titleOptions.route}>
          {titleOptions.title}
        </Layout.Title>
        <Layout.Content>
          <Layout.Column
            className={twMerge(
              "flex h-full max-h-[700px] flex-col gap-4 overflow-hidden mobile:h-[calc(100vh-76px)] mobile:max-h-none tablet:h-[calc(100vh-76px)] tablet:max-h-none",
              isDetailMobile && "hidden",
            )}
          >
            <Parcel
              config={() => System.import("@hyperlocal/banking-balance")}
              mountParcel={mountRootParcel}
            />
            <CardsList />
          </Layout.Column>
          <Layout.Column
            className={twMerge(
              "absolute inset-0 col-[2] overflow-hidden bg-neutral-white mobile:relative mobile:inset-auto mobile:col-auto mobile:h-[calc(100vh-76px)] tablet:relative tablet:inset-auto tablet:col-auto tablet:h-[calc(100vh-76px)]",
              !isDetailMobile && "mobile:hidden tablet:hidden",
            )}
          >
            <div className="shrink-0 rounded-sm bg-neutral-white desktop:hidden">
              <Parcel
                config={() => System.import("@hyperlocal/banking-balance")}
                mountParcel={mountRootParcel}
              />
            </div>
            <CardDetails />
          </Layout.Column>
        </Layout.Content>
      </Layout.Root>
      <Outlet />
    </div>
  );
};
