import { useCallback, useMemo, useState } from "react";
import { useMediaQuery } from "@/utils";
import { Button, Dialog, Sheet } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { CardTypeSelect } from "./CardTypeSelect";
import { FilledAddress } from "./FilledAddress";
import { TemporaryCardWarning } from "./TemporaryCardWarning";

export type Content = "cardType" | "filledAddress" | "temporaryCardWarning";

export const NewCardAction = () => {
  const [content, setContent] = useState<Content>("cardType");

  const isMobile = useMediaQuery("mobile");

  const [searchParam, setSearchParam] = useSearchParams();

  const handleContentChange = useCallback((content: Content) => {
    setContent(content);
  }, []);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      searchParam.delete("tipo");
      return setSearchParam(searchParam);
    }
    const cardId = searchParam.get("id");
    setContent("cardType");
    searchParam.set("tipo", "selecionar");
    if (cardId) searchParam.set("id", cardId);
    setSearchParam(searchParam);
  };

  const isOpen = searchParam.has("tipo");

  const selectedContent = useMemo(() => {
    const contentMap: Record<Content, JSX.Element> = {
      cardType: <CardTypeSelect handleContent={handleContentChange} />,
      filledAddress: <FilledAddress handleContent={handleContentChange} />,
      temporaryCardWarning: (
        <TemporaryCardWarning handleContent={handleContentChange} />
      ),
    };

    return contentMap[content] || contentMap.cardType;
  }, [content, handleContentChange]);

  return (
    <>
      {!isMobile && (
        <div className="mobile:hidden tablet:hidden">
          <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
            <Dialog.Trigger asChild>
              <Button.Root fullWidth>Novo cartão</Button.Root>
            </Dialog.Trigger>
            <Dialog.Content
              className={twJoin(
                "max-w-[372px] overflow-hidden",
                "duration-300 ease-in-out",
                content === "cardType" && "h-[243px]",
                content === "filledAddress" && "h-[347px]",
                content === "temporaryCardWarning" && "h-[291px]",
              )}
            >
              {selectedContent}
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div className="desktop:hidden">
          <Sheet.Root open={isOpen} onOpenChange={handleOpenChange}>
            <Sheet.Trigger asChild>
              <Button.Root fullWidth>Novo cartão</Button.Root>
            </Sheet.Trigger>
            <Sheet.Content side="bottom">{selectedContent}</Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};
