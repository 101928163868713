import { HyperlocalSmallCard } from "@/assets";
import { handleWebTransition, useMediaQuery } from "@/utils";
import { CardIssuanceStatus, CardsResponse } from "@cards/types";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Card } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const statusMap: Record<CardIssuanceStatus, string> = {
  blocked: "Bloqueado",
  inRoute: "Caminho",
  sent: "Enviado",
};

const iconMap: Record<CardIssuanceStatus, IconProps["name"]> = {
  blocked: "GeralSecurityLock",
  inRoute: "ShopShoppingCart",
  sent: "ShopShoppingCart",
};

const creditCardStyles = tv({
  variants: {
    type: {
      physical: "fill-primary-lighter",
      virtual: "fill-neutral-lighter",
      temporary: "fill-secondary-lighter",
    },
    status: {
      true: "opacity-70",
    },
  },
});

const cardInfoStyle = tv({
  base: "ml-auto p-squished-x3s border font-medium text-xs/4 rounded-xs font-base",
  variants: {
    status: {
      blocked:
        "bg-status-warning-light text-status-warning-dark border-status-warning-dark",
      sent: "bg-primary-lighter text-primary-main border-primary-main",
      inRoute: "bg-primary-lighter text-primary-main border-primary-main",
    },
  },
});

const getSvgSize = (isMobile: boolean) => {
  const sizeMap = {
    mobile: {
      height: 30,
      width: 47,
    },
    desktop: {
      height: 43,
      width: 67,
    },
  };

  return isMobile ? sizeMap.mobile : sizeMap.desktop;
};

export const CardItem = ({
  cardNumber,
  name,
  status,
  type,
  id,
}: CardsResponse) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const active = searchParams.get("id") === id;

  const isMobile = useMediaQuery("mobile");

  const svgSize = getSvgSize(isMobile);

  const onSelectCard = (id: string) => {
    if (!isMobile) {
      localStorage.setItem("lastCardSeen", id);
    }
    handleWebTransition(() => setSearchParams(`id=${id}`));
  };

  return (
    <Card
      active={active}
      role="li"
      className={twMerge(
        "group mt-2 flex w-full cursor-pointer items-center gap-4 hover:border-neutral-light",
        active && "hover:border-primary-main",
      )}
      onClick={() => onSelectCard(id)}
      data-state={status}
      data-active={active}
    >
      <div className="relative">
        <HyperlocalSmallCard
          className={creditCardStyles({ type, status: !!status })}
          width={svgSize.width}
          height={svgSize.height}
          data-testid={`${status}-icon`}
        />
        {!!status && (
          <div className="absolute inset-0 mb-auto ml-auto mr-auto mt-auto flex h-fit w-fit items-center justify-center rounded-circle bg-neutral-lighter p-[6px] mobile:p-[4px] tablet:p-[4px]">
            <Icon
              name={iconMap[status]}
              width={20}
              className="fill-current text-neutral-black"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <span
          className={twMerge(
            "text-x2s/[21px] text-neutral-darkest",
            active && "font-bold text-primary-main",
            !!status &&
              "overflow-hidden text-ellipsis whitespace-nowrap mobile:max-w-28 tablet:max-w-28",
          )}
        >
          {name}
        </span>
        <span
          dir="rtl"
          className={twMerge(
            "text-base text-neutral-dark mobile:text-x2s",
            active && "font-bold text-primary-main",
            !!status &&
              "overflow-hidden text-ellipsis whitespace-nowrap text-left mobile:max-w-28 tablet:max-w-28",
          )}
        >
          {cardNumber}
        </span>
      </div>
      {!!status && (
        <span className={cardInfoStyle({ status })}>{statusMap[status]}</span>
      )}
    </Card>
  );
};
