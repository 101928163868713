import { z } from "zod";
import { CARD_TYPE } from "./constants";

const virtualCardSchema = z.object({
  nickname: z.string().min(1, "Apelido é obrigatório"),
});

type VirtualCardSchema = z.infer<typeof virtualCardSchema>;

const temporaryCardSchema = z.object({});

type TemporaryCardSchema = z.infer<typeof temporaryCardSchema>;

export const cardInfoSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal(CARD_TYPE.virtual),
    [CARD_TYPE.virtual]: virtualCardSchema,
  }),
  z.object({
    type: z.literal(CARD_TYPE.temporary),
    [CARD_TYPE.temporary]: temporaryCardSchema,
  }),
  z.object({
    type: z.literal(CARD_TYPE.select),
    [CARD_TYPE.select]: temporaryCardSchema,
  }),
]);

export type CardInfoSchema = {
  type: keyof typeof CARD_TYPE;
  [CARD_TYPE.virtual]: VirtualCardSchema;
  [CARD_TYPE.temporary]: TemporaryCardSchema;
};
