import { ReactNode } from "react";
import { routes } from "@/utils";
import { NewCardFormSchema, newCardPaths } from "@newCard/utils";
import { useFormContext } from "react-hook-form";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

export const NewCardNavigation = ({ children }: { children: ReactNode }) => {
  const { getValues } = useFormContext<NewCardFormSchema>();

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const cardId = searchParams.get("id");

  const { cardInfo } = getValues();

  const isNewCardLocation = newCardPaths.some(
    (routePath) => routePath === location.pathname,
  );

  if (isNewCardLocation && cardInfo.type === "select") {
    return (
      <Navigate
        to={`${routes.cards}?tipo=selecionar${cardId ? "&id=" + cardId : ""}`}
      />
    );
  }

  return <div>{children}</div>;
};
