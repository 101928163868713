import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { City } from "./useAddress.types";

export const getCities = async (state: string) => {
  try {
    const response = await axios.get<City[]>(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

interface useGetCities {
  state: string;
  options?: UseQueryOptions<City[]>;
}

export function useGetCities({ state, options }: useGetCities) {
  return useQuery<City[]>({
    ...options,
    queryKey: ["cep", state],
    queryFn: () => getCities(state),
    enabled: !!state,
    staleTime: 2 * 60 * 1000,
  });
}
