import { z } from "zod";
import { deliveryAddressSchema, DeliveryAddressSchema } from "./addressSchema";
import { cardholderSchema, CardholderSchema } from "./cardholderSchema";
import { cardInfoSchema, CardInfoSchema } from "./cardInfoSchema";
import { FORM_TYPE } from "./constants";

export const newCardFormSchema = z.discriminatedUnion("formType", [
  z.object({
    formType: z.literal(FORM_TYPE.cardholder),
    [FORM_TYPE.cardholder]: cardholderSchema,
  }),
  z.object({
    formType: z.literal(FORM_TYPE.deliveryAddress),
    [FORM_TYPE.deliveryAddress]: deliveryAddressSchema,
  }),
  z.object({
    formType: z.literal(FORM_TYPE.cardInfo),
    [FORM_TYPE.cardInfo]: cardInfoSchema,
  }),
]);

export type NewCardFormSchema = {
  formType: keyof typeof FORM_TYPE;
  cardholder: CardholderSchema;
  deliveryAddress: DeliveryAddressSchema;
  cardInfo: CardInfoSchema;
};
