import { routes, TOTPActions } from "@/utils";
import { Button, Dialog } from "@hyperlocal/vital2";
import { NewCardFormSchema } from "@newCard/utils";
import { useFormContext } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Content } from ".";

interface TemporaryCardWarningProps {
  handleContent: (content: Content) => void;
}

export const TemporaryCardWarning = ({
  handleContent,
}: TemporaryCardWarningProps) => {
  const { setValue } = useFormContext<NewCardFormSchema>();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cardId = searchParams.get("id");

  const onGenerateCard = () => {
    setValue("cardInfo.type", "temporary");
    navigate(
      {
        pathname: routes.newCardConfirmTOTP,
        search: cardId && `id=${cardId}`,
      },
      { state: { TOTPAction: TOTPActions.createTemporaryCard } },
    );
  };

  return (
    <div className="flex flex-col gap-inset-x2s duration-300 animate-in fade-in">
      <Dialog.Header>
        <Dialog.Title>Cartão temporário</Dialog.Title>
      </Dialog.Header>
      <span>
        Este cartão vai expirar em 24 horas e é indicado para compras eventuais
        ou em sites que você não conhece.
      </span>
      <span>
        Tudo pelo aplicativo e usando o saldo da sua conta. Você só pode ter um
        cartão temporário por vez.
      </span>
      <div className="flex gap-2">
        <Button.Root
          variant="secondary"
          fullWidth
          onClick={() => handleContent("cardType")}
        >
          Não
        </Button.Root>
        <Button.Root onClick={onGenerateCard} fullWidth>
          Gerar
        </Button.Root>
      </div>
    </div>
  );
};
