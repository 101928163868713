import { ComponentProps, useState } from "react";
import { CARD_PIN_MIN_LENGTH } from "@/features/newCard/utils";
import Icon from "@hyperlocal/vital-icons";
import { TextField, Tooltip } from "@hyperlocal/vital2";
import { tv } from "tailwind-variants";

type InputProps = {
  label: string;
} & ComponentProps<typeof TextField.Input>;

export const Input = ({ label, ...rest }: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <div>
      <TextField.Label htmlFor={`pin-${label}`}>{label}</TextField.Label>
      <TextField.Root>
        <TextField.Input
          {...rest}
          id={`pin-${label}`}
          name={`pin-${label}`}
          type={showPassword ? "text" : "password"}
          maxLength={4}
          inputMode="numeric"
          pattern="[0-9]*"
        />
        <button
          type="button"
          aria-label="show-password"
          onClick={handlePasswordToggle}
        >
          <TextField.Slot
            name={showPassword ? "ComputersEye" : "ComputersEyeSlash"}
          />
        </button>
      </TextField.Root>
    </div>
  );
};

export const HelperTooltip = ({ message }: { message: string }) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger type="button">
          <Icon
            name="TagsAlertsInfoCircle"
            fill="currentColor"
            className="size-4 text-primary-main"
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>{message}</Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export const liStyles = tv({
  base: "font-base text-base/6 text-neutral-dark flex gap-2 items-center",
  variants: {
    fullfilled: {
      true: "text-status-success-dark",
    },
  },
});

export const rules = [
  {
    label: "Senhas são iguais",
    id: "coincide",
  },
  {
    label: "Senha de 4 números",
    id: "validLength",
  },
  {
    label: "Contém 3 números diferentes",
    id: "differentNumbers",
  },
  {
    label: "Não contém números repetitivos",
    id: "repetitiveNumbers",
    helper: "Ex: 11 / 22 / 33",
  },
];

/* 
  Regras
  1º: 4 Dígitos.
  2º: Pelo menos 3 números diferentes
  3º Não pode ter números repetidos em sequência. Ex: 1159 | 3446
  4º Senhas são iguais
*/
export const checkPinRules = ({
  pin,
  pinConfirmation,
}: {
  pin: string;
  pinConfirmation: string;
}) => {
  const validLength = pin.length === CARD_PIN_MIN_LENGTH;

  const threeDifferentNumbers = new Set(pin).size >= 3;

  const repetitiveNumbers = pin.split("").some((number, index, array) => {
    if (array[index - 1]) {
      return number === array[index - 1];
    }
    return false;
  });

  return {
    validLength,
    differentNumbers: threeDifferentNumbers && validLength,
    repetitiveNumbers: !repetitiveNumbers && validLength,
    coincide: pin === pinConfirmation && validLength,
  };
};
