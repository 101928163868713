import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { UF } from "./useAddress.types";

const getStates = async () => {
  try {
    const response = await axios.get<UF[]>(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados",
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const useStates = (options?: UseQueryOptions<UF[]>) => {
  return useQuery({
    ...options,
    queryKey: ["getStates"],
    queryFn: () => getStates(),
  });
};

export const useGetStates = () => {
  const { data, isLoading } = useStates();

  return { isLoading, data: data || [] };
};
