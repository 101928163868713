import { z } from "zod";

export const deliveryAddressSchema = z.object({
  zipcode: z.string().min(8, "CEP é obrigatório."),
  address: z.string().min(1, "Endereço é obrigatório."),
  number: z.string().min(1, "Número é obrigatório"),
  neighborhood: z.string().min(1, "Bairro é obrigatório"),
  city: z.string().min(1, "Cidade é obrigatório"),
  state: z.string().min(1, "Estado é obrigatório"),
  complement: z.string().optional(),
  citiesList: z.array(
    z.object({
      id: z.number(),
      nome: z.string(),
    }),
  ),
});

export type DeliveryAddressSchema = z.infer<typeof deliveryAddressSchema>;
