export function CheckedStatusIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={2} y={2} width={20} height={20} rx={10} fill="#00801E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.333 8l-5.528 5.529-2.862-2.862-.943.942 3.333 3.334a.67.67 0 00.943 0l6-6L16.333 8z"
        fill="#fff"
      />
    </svg>
  );
}
