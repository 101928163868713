import { ComponentProps } from "react";

export function CheckedCircle(props: ComponentProps<"svg">) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7 6.683A6.703 6.703 0 0114.833 8c0 .878-.166 1.7-.5 2.466a6.438 6.438 0 01-1.358 2.009 6.438 6.438 0 01-2.008 1.358 6.12 6.12 0 01-2.467.5 6.12 6.12 0 01-2.466-.5 6.447 6.447 0 01-2.009-1.358 6.442 6.442 0 01-1.358-2.008A6.119 6.119 0 012.167 8c0-.878.167-1.7.5-2.467a6.45 6.45 0 011.358-2.009 6.45 6.45 0 012.009-1.357 6.119 6.119 0 012.466-.5c.59 0 1.156.077 1.7.233.545.155 1.05.372 1.517.65a.482.482 0 01.225.317.443.443 0 01-.108.383.566.566 0 01-.334.208.502.502 0 01-.383-.075 4.71 4.71 0 00-1.233-.533A5.193 5.193 0 008.5 2.667c-1.477 0-2.736.519-3.774 1.558C3.686 5.263 3.167 6.521 3.167 8c0 1.478.52 2.736 1.559 3.775 1.038 1.039 2.297 1.558 3.774 1.558 1.478 0 2.736-.52 3.776-1.558 1.038-1.039 1.558-2.297 1.558-3.775a4.649 4.649 0 00-.134-1.117.578.578 0 01.05-.309.523.523 0 01.2-.224c.156-.111.309-.128.459-.05.15.078.247.205.291.383zm-7.566 3.733l-1.85-1.85a.436.436 0 01-.125-.341.49.49 0 01.141-.342c.1-.1.22-.15.359-.15.139 0 .252.05.341.15l1.55 1.55 6.25-6.25a.414.414 0 01.342-.142.464.464 0 01.342.159c.1.089.15.202.15.341a.49.49 0 01-.15.359l-6.5 6.516a.623.623 0 01-.434.167.565.565 0 01-.416-.167z"
        fill="#fff"
      />
    </svg>
  );
}
