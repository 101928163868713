import { handleWebTransition, routes } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField } from "@hyperlocal/vital2";
import { Layout } from "@newCard/components";
import {
  CardholderSchema,
  cardholderSchema,
  MASKED_INPUT_DEFAULT_PROPS,
  NewCardFormSchema,
} from "@newCard/utils";
import { useForm, useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useHookFormMask } from "use-mask-input";

export const CardholderData = () => {
  const { getValues, setValue } = useFormContext<NewCardFormSchema>();

  const contextValues = getValues();

  const {
    formState: { errors },
    register,
    clearErrors,
    handleSubmit,
  } = useForm<CardholderSchema>({
    resolver: zodResolver(cardholderSchema),
    defaultValues: contextValues.cardholder,
  });

  const navigate = useNavigate();
  const { search } = useLocation();

  const registerWithMask = useHookFormMask(register);

  const handleUpdateCardholder = (cardholder: CardholderSchema) => {
    handleWebTransition(() => {
      setValue("cardholder", cardholder);
      clearErrors();
      navigate({ pathname: routes.newCardResume, search });
    });
  };

  const handleInputErrorStatus = (error: boolean): "error" | "success" => {
    if (error) return "error";
  };

  return (
    <Layout.Root>
      <Layout.Link to={{ pathname: routes.newCardResume, search }}>
        Dados do titular
      </Layout.Link>
      <Layout.Content>
        <form
          className="flex h-full flex-1 flex-col justify-between"
          onSubmit={handleSubmit(handleUpdateCardholder)}
        >
          <div className="flex h-full flex-col gap-inset-lg">
            <div>
              <TextField.Label htmlFor="name">
                Titular do cartão
              </TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="name"
                  {...register("name")}
                  status={handleInputErrorStatus(!!errors?.name?.message)}
                />
              </TextField.Root>
              <TextField.Helper>{errors?.name?.message}</TextField.Helper>
            </div>
            <div>
              <TextField.Label htmlFor="document">CPF</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="document"
                  {...registerWithMask(
                    "document",
                    "999.999.999-99",
                    MASKED_INPUT_DEFAULT_PROPS,
                  )}
                  status={handleInputErrorStatus(!!errors?.document?.message)}
                />
              </TextField.Root>
              <TextField.Helper>{errors?.document?.message}</TextField.Helper>
            </div>
            <div>
              <TextField.Label htmlFor="cellphone">Celular</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="cellphone"
                  {...registerWithMask(
                    "cellphone",
                    "(99) 99999-9999",
                    MASKED_INPUT_DEFAULT_PROPS,
                  )}
                  status={handleInputErrorStatus(!!errors?.cellphone?.message)}
                />
              </TextField.Root>
              <TextField.Helper>{errors?.cellphone?.message}</TextField.Helper>
            </div>
            <div>
              <TextField.Label htmlFor="email">E-mail</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  status={handleInputErrorStatus(!!errors?.email?.message)}
                  id="email"
                  type="email"
                  {...register("email")}
                />
              </TextField.Root>
              <TextField.Helper>{errors?.email?.message}</TextField.Helper>
            </div>
          </div>
          <div className="mt-16 flex">
            <Button.Root
              fullWidth
              variant="link"
              type="button"
              onClick={() => navigate({ pathname: routes.cards, search })}
            >
              Cancelar
            </Button.Root>
            <Button.Root type="submit" fullWidth>
              Próxima etapa
            </Button.Root>
          </div>
        </form>
      </Layout.Content>
    </Layout.Root>
  );
};
