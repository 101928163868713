import { useEffect, useState } from "react";
import { CheckedCircle } from "@/assets";
import { handleWebTransition, routes } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Button, TextField, toast } from "@hyperlocal/vital2";
import { Layout } from "@newCard/components";
import { MASKED_INPUT_DEFAULT_PROPS } from "@newCard/utils";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { withMask } from "use-mask-input";

const CODE_LENGTH = 6;

export const ConfirmMFA = () => {
  const [code, setCode] = useState("");

  const { reset } = useFormContext();

  const navigate = useNavigate();

  const { search, state } = useLocation();

  const TOTPAction = state?.TOTPAction;

  const goToCards = () => {
    handleWebTransition(() =>
      navigate({ pathname: routes.cards, search }, { replace: true })
    );
  };

  const handleConfirm = () => {
    toast({
      title: "Tudo certo meu chapa",
      variant: "success",
      icon: <CheckedCircle color="#fff" />,
      closeable: false,
    });

    reset();

    goToCards();
  };

  useEffect(() => {
    if (!TOTPAction) return navigate({ pathname: routes.cards, search });
  }, [TOTPAction, navigate, search]);

  return (
    <>
      <Layout.Root>
        <Layout.Link className="flex justify-between">
          Validação
          <button className="desktop:hidden" onClick={goToCards}>
            <Icon
              name="GeralClose"
              fill="currentColor"
              width={24}
              className="text-neutral-dark"
            />
          </button>
        </Layout.Link>
        <Layout.Title>Informe o código de autenticação</Layout.Title>
        <Layout.Content>
          <div className="flex gap-2">
            <span>●</span>
            <span>
              Abra seu aplicativo Google Authenticator e insira o código de seis
              dígitos exibido no aplicativo.
            </span>
          </div>
          <TextField.Label htmlFor="totp" className="mt-inset-md">
            Digite o código
          </TextField.Label>
          <TextField.Root>
            <TextField.Input
              id="totp"
              type="text"
              ref={withMask("999999", MASKED_INPUT_DEFAULT_PROPS)}
              value={code}
              onChange={({ target }) => setCode(target.value)}
            />
          </TextField.Root>
          <Button.Root
            className="mt-auto"
            onClick={handleConfirm}
            disabled={code.length !== CODE_LENGTH}
          >
            Verificar
          </Button.Root>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};
