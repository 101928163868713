import { ReactNode, useEffect, useMemo } from "react";
import { routes } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CARD_TYPE,
  FORM_TYPE,
  newCardFormSchema,
  NewCardFormSchema,
} from "@newCard/utils";
import { FormProvider as ReactFormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const FormProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const formType = useMemo(() => {
    const formTypeMap: Record<string, keyof typeof FORM_TYPE> = {
      [routes.newCardAddress]: FORM_TYPE.deliveryAddress,
      [routes.newCardholder]: FORM_TYPE.cardholder,
      [routes.newCardNickname]: FORM_TYPE.cardInfo,
    };

    return formTypeMap[location.pathname] || formTypeMap[routes.newCardAddress];
  }, [location.pathname]);

  const methods = useForm<NewCardFormSchema>({
    resolver: zodResolver(newCardFormSchema),
    defaultValues: {
      formType,
      cardInfo: { type: CARD_TYPE.select },
      cardholder: { cellphone: "", document: "", email: "", name: "" },
      deliveryAddress: {
        address: "",
        city: "",
        complement: "",
        neighborhood: "",
        number: "",
        state: "",
        zipcode: "",
        citiesList: [],
      },
    },
  });

  useEffect(() => {
    methods.setValue("formType", formType);
  }, [formType, methods]);

  useEffect(() => {
    if (location.pathname === routes.cards) return methods.reset();
  }, [location.pathname, methods]);

  return <ReactFormProvider {...methods}>{children}</ReactFormProvider>;
};
