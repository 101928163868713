import { CEP_LENGTH } from "@newCard/utils";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import cepPromise from "cep-promise";
import { CepPromiseError, CepPromiseSuccess } from "./useAddress.types";

interface UseCep {
  cep: string;
  options?: UseQueryOptions<CepPromiseSuccess>;
}

export function useCep({ cep, options }: UseCep) {
  return useQuery<CepPromiseSuccess, CepPromiseError>({
    ...options,
    queryKey: ["cep", cep],
    queryFn: () => cepPromise(cep),
    enabled: cep.length === CEP_LENGTH,
    staleTime: 2 * 60 * 1000,
    meta: {
      errorMessage: "Houve um erro ao procurar o CEP",
    },
  });
}
