import { useState } from "react";

export const useTooltipTimer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, 1500);
    }
  };

  return [isOpen, handleClick] as const;
};
