import { routes } from "@/utils";
import { Button, Dialog } from "@hyperlocal/vital2";
import { NewCardFormSchema } from "@newCard/utils";
import { useFormContext } from "react-hook-form";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Content } from ".";

interface CardTypeSelectProps {
  handleContent: (content: Content) => void;
}

export const FilledAddress = ({ handleContent }: CardTypeSelectProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  const cardId = searchParams.get("id");

  const { setValue } = useFormContext<NewCardFormSchema>();

  const handleNavigation = (to: To, options?: NavigateOptions) => {
    handleContent("cardType");
    setValue("cardInfo.type", "physical");
    navigate(to, options);
  };

  return (
    <div className="flex flex-col gap-inset-x2s duration-300 animate-in fade-in">
      <Dialog.Header>
        <Dialog.Title>Endereço de entrega</Dialog.Title>
      </Dialog.Header>
      <div className="flex flex-col gap-inset-md">
        <span>Temos o seguinte endereço registrado em nossos dados:</span>
        <ul>
          <li className="list-inside list-disc font-bold">
            R. Bela Cintra, 1149 - ANDAR 14 SALA 14B - Consolação, São Paulo -
            SP, 01415-003
          </li>
        </ul>

        <span>
          Gostaria de usar esse endereço para receber o seu cartão físico?
        </span>
        <Dialog.Footer className="flex gap-inset-x2s">
          <Button.Root
            variant="secondary"
            fullWidth
            onClick={() =>
              handleNavigation({
                pathname: routes.newCardAddress,
                search: cardId && `id=${cardId}`,
              })
            }
          >
            Não
          </Button.Root>
          <Button.Root
            fullWidth
            variant="primary"
            onClick={() =>
              handleNavigation(
                {
                  pathname: routes.newCardResume,
                  search: cardId && `id=${cardId}`,
                },
                {
                  state: { from: `${routes.cards}${search}` },
                },
              )
            }
          >
            Sim
          </Button.Root>
        </Dialog.Footer>
      </div>
    </div>
  );
};
