import { useEffect } from "react";
import { handleWebTransition, routes } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Select, TextField } from "@hyperlocal/vital2";
import { useCep, useGetCities, useGetStates } from "@newCard/api";
import { Layout } from "@newCard/components";
import {
  DeliveryAddressSchema,
  deliveryAddressSchema,
  MASKED_INPUT_DEFAULT_PROPS,
  NewCardFormSchema,
} from "@newCard/utils";
import { useForm, useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useHookFormMask } from "use-mask-input";

export const DeliveryAddress = () => {
  const { getValues, setValue } = useFormContext<NewCardFormSchema>();

  const contextValues = getValues();

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue: setAddressValue,
    watch,
    clearErrors,
  } = useForm<DeliveryAddressSchema>({
    resolver: zodResolver(deliveryAddressSchema),
    defaultValues: contextValues.deliveryAddress,
  });

  const { data: statesList } = useGetStates();

  const { data, isLoading } = useCep({
    cep: watch("zipcode"),
  });

  const { data: citiesList } = useGetCities({ state: data?.state });

  const navigate = useNavigate();

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const cardId = searchParams.get("id");

  const previousRoute =
    location.state?.from ||
    `${routes.cards}?tipo=selecionar${cardId ? "&id=" + cardId : ""}`;

  const nextRoute =
    location.state?.to || routes.newCardResume + location.search;

  const registerWithMask = useHookFormMask(register);

  const handleClientAddress = (deliveryAddress: DeliveryAddressSchema) => {
    handleWebTransition(() => {
      setValue("deliveryAddress", deliveryAddress);
      navigate(nextRoute);
    });
  };

  const handleInputErrorStatus = (error: boolean): "error" | "success" => {
    if (error) return "error";
  };

  useEffect(() => {
    if (data) {
      const updateFormValues = () => {
        setAddressValue("address", data?.street);
        setAddressValue("state", data?.state);
        setAddressValue("neighborhood", data?.neighborhood);
        setAddressValue("city", data?.city);
        clearErrors();
      };

      updateFormValues();
    }
  }, [
    clearErrors,
    data?.neighborhood,
    data?.state,
    data?.street,
    data?.city,
    setAddressValue,
    data,
  ]);

  return (
    <Layout.Root>
      <Layout.Link to={previousRoute}>Novo cartão físico</Layout.Link>
      <Layout.Title>Endereço de entrega</Layout.Title>
      <Layout.Content>
        <form
          onSubmit={handleSubmit(handleClientAddress)}
          className="flex h-full flex-1 flex-col justify-between"
        >
          <div className="grid grid-cols-4 gap-inset-md">
            <div className="col-start-1 col-end-3">
              <TextField.Label htmlFor="zipcode">CEP</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  {...registerWithMask(
                    "zipcode",
                    "99999-999",
                    MASKED_INPUT_DEFAULT_PROPS,
                  )}
                  id="zipcode"
                  type="text"
                  status={handleInputErrorStatus(!!errors?.zipcode?.message)}
                  placeholder="00000-000"
                />
              </TextField.Root>
              <TextField.Helper>{errors?.zipcode?.message}</TextField.Helper>
            </div>
            <Button.Root
              className={twJoin(
                "col-start-3 col-end-5",
                errors?.zipcode?.message ? "self-center" : "self-end",
              )}
              variant="link"
              fullWidth
              isLoading={isLoading}
            >
              Nâo sei o CEP
            </Button.Root>
            <div className="col-start-1 col-end-4">
              <TextField.Label htmlFor="address">Endereço</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="address"
                  value={data?.street || watch("address")}
                  status={handleInputErrorStatus(!!errors?.address?.message)}
                  placeholder="Digite aqui"
                  {...register("address")}
                />
              </TextField.Root>
              <TextField.Helper>{errors?.address?.message}</TextField.Helper>
            </div>
            <div>
              <TextField.Label htmlFor="number">Número</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  {...registerWithMask(
                    "number",
                    "99999999",
                    MASKED_INPUT_DEFAULT_PROPS,
                  )}
                  id="number"
                  status={handleInputErrorStatus(!!errors?.number?.message)}
                  placeholder="0"
                />
              </TextField.Root>
              <TextField.Helper
                title={errors?.number?.message}
                className="overflow-hidden text-ellipsis whitespace-nowrap"
              >
                {errors?.number?.message}
              </TextField.Helper>
            </div>
            <div className="col-start-1 col-end-5">
              <TextField.Label htmlFor="neighborhood">Bairro</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="neighborhood"
                  value={data?.neighborhood || watch("neighborhood")}
                  status={handleInputErrorStatus(
                    !!errors?.neighborhood?.message,
                  )}
                  placeholder="Digite aqui"
                  {...register("neighborhood")}
                />
              </TextField.Root>
              <TextField.Helper>
                {errors?.neighborhood?.message}
              </TextField.Helper>
            </div>
            <div className="col-start-1 col-end-4">
              <Select.Root
                value={data?.city || watch("city")}
                onValueChange={(city) => setAddressValue("city", city)}
              >
                <Select.Label htmlFor="city">Cidade</Select.Label>
                <Select.Trigger
                  disabled={!citiesList?.length}
                  id="city"
                  className="w-full gap-1"
                >
                  <Select.Value placeholder="Selecione" />
                </Select.Trigger>
                <Select.Content>
                  <Select.Group>
                    {citiesList?.map((city) => (
                      <Select.Item value={city.nome} key={city.id}>
                        {city.nome}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </div>
            <div>
              <Select.Root
                value={data?.state || watch("state")}
                onValueChange={(state) => setAddressValue("state", state)}
                defaultValue={statesList[0]?.sigla || "SP"}
              >
                <Select.Label htmlFor="state">Estado</Select.Label>
                <Select.Trigger id="state" className="w-full gap-1">
                  <Select.Value placeholder="SP" />
                </Select.Trigger>
                <Select.Content>
                  <Select.Group>
                    {statesList?.map((state) => (
                      <Select.Item key={state.id} value={state.sigla}>
                        {state.sigla}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </div>
            <div className="col-start-1 col-end-5">
              <TextField.Label htmlFor="complement">
                Complemento
              </TextField.Label>
              <TextField.Root>
                <TextField.Input
                  {...register("complement")}
                  id="complement"
                  placeholder="Digite aqui"
                />
              </TextField.Root>
            </div>
          </div>
          <div className="mt-16 flex">
            <Button.Root
              onClick={() =>
                navigate({ pathname: routes.cards, search: location.search })
              }
              type="button"
              fullWidth
              variant="link"
            >
              Cancelar
            </Button.Root>
            <Button.Root fullWidth type="submit">
              Próxima etapa
            </Button.Root>
          </div>
        </form>
      </Layout.Content>
    </Layout.Root>
  );
};
