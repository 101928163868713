import { z } from "zod";

export const cardInformationSchema = z.object({
  cardNumber: z.string().min(16, "Número do cartão é obrigatório"),
  cardName: z.string().min(1, "Nome no cartão é obrigatório"),
  expirationDate: z.string().min(4, "Data de expiração é obrigatório"),
  CVVCode: z.string().min(3, "Código CVV é obrigatório"),
});

export type CardInformationSchema = z.infer<typeof cardInformationSchema>;
