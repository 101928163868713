import EmptyListImg from "@/assets/EmptyList.png";
import { NewCardAction, ScrollArea } from "@/components";
import { useCardsList } from "@cards/api";
import { CardItem } from "./components";

export const CardsList = () => {
  const { data: cards, emptyCards } = useCardsList();

  if (emptyCards) {
    return (
      <div className="flex h-full flex-col justify-between rounded-sm bg-neutral-white p-6">
        <div className="flex h-full flex-col items-center justify-center">
          <img src={EmptyListImg} alt="Empty list" width={146} />
          <span className="max-w-[225px] text-center" role="alert">
            Aqui está vazio! Cadastre um novo cartão.
          </span>
        </div>
        <NewCardAction />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col justify-between overflow-hidden bg-neutral-white">
      <ScrollArea className="rounded-sm p-6 pb-0 mobile:px-6 mobile:py-0 tablet:px-6 tablet:py-0">
        {!!cards.physical.length && (
          <div>
            <h5 className="text-sm/8 font-bold">Cartões físicos</h5>
            <ul aria-label="Cartões físicos" className="mt-4">
              {cards.physical.map((card) => (
                <CardItem key={card.id} {...card} />
              ))}
            </ul>
          </div>
        )}
        {!!cards.virtual.length && (
          <div>
            <h5 className="mt-4 text-sm/8 font-bold">Cartões virtuais</h5>
            <ul aria-label="Cartões virtuais" className="mt-4">
              {cards.virtual.map((card) => (
                <CardItem key={card.id} {...card} />
              ))}
            </ul>
          </div>
        )}
        {!!cards.temporary.length && (
          <div>
            <h5 className="mt-4 text-sm/8 font-bold">Cartões temporários</h5>
            <ul aria-label="Cartões temporários" className="mt-4">
              {cards.temporary.map((card) => (
                <CardItem key={card.id} {...card} />
              ))}
            </ul>
          </div>
        )}
      </ScrollArea>
      <div className="p-6">
        <NewCardAction />
      </div>
    </div>
  );
};
