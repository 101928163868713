import { ComponentProps } from "react";

export function LockTimer(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 8.5h6v-2c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0012 3.5c-.833 0-1.542.292-2.125.875A2.893 2.893 0 009 6.5v2zm3.45 13H6.3c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 19.7v-9.4c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h1.2v-2c0-1.25.437-2.313 1.312-3.188S10.75 2 12 2c1.25 0 2.313.437 3.188 1.312S16.5 5.25 16.5 6.5v2h1.2c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v1.25a5.45 5.45 0 00-.7-.163 4.91 4.91 0 00-.8-.062V10.3a.292.292 0 00-.087-.213A.292.292 0 0017.7 10H6.3a.289.289 0 00-.212.087A.29.29 0 006 10.3v9.4c0 .083.03.154.088.213A.289.289 0 006.3 20h5.425c.1.3.204.563.313.788.108.225.245.462.412.712zm5.35.8c-1.25 0-2.312-.437-3.187-1.312S13.3 19.05 13.3 17.8c0-1.25.438-2.313 1.313-3.188S16.55 13.3 17.8 13.3c1.25 0 2.313.437 3.188 1.312S22.3 16.55 22.3 17.8c0 1.25-.437 2.313-1.312 3.188S19.05 22.3 17.8 22.3zm1.65-2.225l.625-.625-1.825-1.825v-2.75h-.875V18l2.075 2.075z"
        fill="#0047F6"
      />
    </svg>
  );
}
