import { routes } from "@/utils";
import { Sheet } from "@hyperlocal/vital2";
import { newCardPaths } from "@newCard/utils";
import {
  Outlet as ReactRouterOutlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";

const outletPaths = [
  ...newCardPaths,
  routes.newCardConfirmTOTP,
  routes.confirmCardDelivery,
  routes.cardNewPassword,
  routes.confirmTOTP,
];

const closeablePaths = [
  routes.newCardConfirmTOTP,
  routes.confirmCardDelivery,
  routes.cardNewPassword,
];

export const Outlet = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const handleClose = (open: boolean) => {
    if (!open)
      return navigate(
        { pathname: routes.cards, search: location.search },
        { replace: true },
      );
  };

  const isSheetOpen = outletPaths.some((path) => location.pathname === path);

  const closeable = closeablePaths.some((path) => location.pathname === path);

  return (
    <Sheet.Root onOpenChange={handleClose} open={isSheetOpen}>
      <Sheet.Content
        onEscapeKeyDown={(e) => {
          if (!closeable) e.preventDefault();
        }}
        onPointerDownOutside={(e) => {
          if (!closeable) e.preventDefault();
        }}
        className={twMerge(!closeable && "[&>[data-role=close-sheet]]:hidden")}
      >
        <ReactRouterOutlet />
      </Sheet.Content>
    </Sheet.Root>
  );
};
