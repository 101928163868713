import {
  ComponentPropsWithoutRef,
  ComponentRef,
  ElementRef,
  forwardRef,
} from "react";
import { handleWebTransition } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { To, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const LayoutRoot = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "flex h-full flex-1 flex-col mobile:pb-inset-xl tablet:pb-inset-xl",
      className,
    )}
    {...rest}
  />
));
LayoutRoot.displayName = "LayoutRoot";

interface LayoutLinkProps extends ComponentPropsWithoutRef<"h5"> {
  to?: To;
}

const LayoutLink = forwardRef<ElementRef<"h5">, LayoutLinkProps>(
  ({ className, children, to, ...rest }, ref) => {
    const navigate = useNavigate();

    const handleNavigate = () => handleWebTransition(() => navigate(to));

    return (
      <h5
        className={twMerge(
          "mb-inset-xl flex w-full items-center gap-inset-x2s font-base text-2xl/9 font-bold text-neutral-darkest mobile:my-0 mobile:mb-3 mobile:px-inset-md mobile:py-inset-md mobile:pb-inset-md mobile:text-sm/5 mobile:shadow-lower tablet:my-0 tablet:mb-3 tablet:px-inset-md tablet:py-inset-md tablet:pb-inset-md tablet:text-sm/5 tablet:shadow-lower",
          className,
        )}
        ref={ref}
        {...rest}
      >
        {to && (
          <button onClick={handleNavigate}>
            <Icon
              name="ArrowNoLineLeft"
              fill="currentColor"
              className="size-inset-md"
            />
          </button>
        )}
        {children}
      </h5>
    );
  },
);
LayoutLink.displayName = "LayoutLink";

const LayoutTitle = forwardRef<
  ComponentRef<"h5">,
  ComponentPropsWithoutRef<"h5">
>(({ className, ...rest }, ref) => (
  <h5
    ref={ref}
    className={twMerge(
      "mb-inset-md font-base text-sm font-bold mobile:px-inset-md tablet:px-inset-md",
      className,
    )}
    {...rest}
  />
));
LayoutTitle.displayName = "LayoutTitle";

const LayoutContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "flex h-full flex-1 flex-col justify-between mobile:px-inset-md tablet:px-inset-md",
      className,
    )}
    {...rest}
  />
));
LayoutContent.displayName = "LayoutContent";

export const Layout = {
  Root: LayoutRoot,
  Link: LayoutLink,
  Title: LayoutTitle,
  Content: LayoutContent,
};
