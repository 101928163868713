import React from "react";
import { ErrorFeedback } from "@/components";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { App } from "./App";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return (
      <QueryErrorResetBoundary>
        <ErrorFeedback
          className="min-h-[80vh]"
          onRetry={() => window.location.reload()}
        />
      </QueryErrorResetBoundary>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
