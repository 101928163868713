import {
  handleWebTransition,
  MASKED_INPUT_DEFAULT_PROPS,
  routes,
  TOTPActions,
} from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import Icon from "@hyperlocal/vital-icons";
import { Button, TextField } from "@hyperlocal/vital2";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useHookFormMask } from "use-mask-input";
import {
  CardInformationSchema,
  cardInformationSchema,
} from "./cardInformationSchema";

export const ConfirmCardDelivery = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CardInformationSchema>({
    resolver: zodResolver(cardInformationSchema),
    defaultValues: {
      cardName: "",
      cardNumber: "",
      CVVCode: "",
      expirationDate: "",
    },
  });

  const registerWithMask = useHookFormMask(register);

  const handleInputErrorStatus = (error: boolean): "error" | "success" => {
    if (error) return "error";
  };

  const onSubmit = () => {
    handleWebTransition(() =>
      navigate(
        { pathname: routes.newCardConfirmTOTP, search },
        {
          state: {
            TOTPAction: TOTPActions.confirmCardDelivery,
          },
        },
      ),
    );
  };

  const onClose = () => {
    handleWebTransition(() => navigate({ pathname: routes.cards, search }));
  };

  return (
    <div className="flex h-full flex-col mobile:min-h-[100vh] mobile:p-4 mobile:pb-8 tablet:min-h-[100vh] tablet:p-4 tablet:pb-8">
      <h4 className="mb-8 flex justify-between font-base text-2xl font-bold text-neutral-darkest mobile:text-sm/5 tablet:text-sm/5">
        Desbloqueio do cartão
        <button className="desktop:hidden" onClick={onClose}>
          <Icon
            name="GeralClose"
            fill="currentColor"
            width={24}
            className="text-neutral-dark"
          />
        </button>
      </h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-full flex-1 flex-col gap-4"
      >
        <h5 className="font-base text-sm/[30px] font-bold text-neutral-darkest mobile:text-base tablet:text-base">
          Informações do cartão
        </h5>
        <div>
          <TextField.Label htmlFor="cardNumber">
            Número do cartão
          </TextField.Label>
          <TextField.Root>
            <TextField.Input
              {...registerWithMask(
                "cardNumber",
                "9999 9999 9999 9999",
                MASKED_INPUT_DEFAULT_PROPS,
              )}
              id="cardNumber"
              type="text"
              placeholder="0000 0000 0000 0000"
              status={handleInputErrorStatus(!!errors?.cardNumber?.message)}
            />
          </TextField.Root>
          <TextField.Helper>{errors?.cardNumber?.message}</TextField.Helper>
        </div>
        <div>
          <TextField.Label htmlFor="cardName">Nome no cartão</TextField.Label>
          <TextField.Root>
            <TextField.Input
              {...register("cardName")}
              id="cardName"
              type="text"
              placeholder="Digite aqui..."
              status={handleInputErrorStatus(!!errors?.cardName?.message)}
            />
          </TextField.Root>
          <TextField.Helper>{errors?.cardName?.message}</TextField.Helper>
        </div>
        <div className="flex gap-4">
          <div className="max-w-[50%]">
            <TextField.Label htmlFor="expirationDate">
              Data de expiração
            </TextField.Label>
            <TextField.Root>
              <TextField.Input
                {...registerWithMask(
                  "expirationDate",
                  "99/99",
                  MASKED_INPUT_DEFAULT_PROPS,
                )}
                id="expirationDate"
                type="text"
                placeholder="00/00"
                status={handleInputErrorStatus(
                  !!errors?.expirationDate?.message,
                )}
              />
            </TextField.Root>
            <TextField.Helper
              title={errors?.expirationDate?.message}
              className="overflow-hidden text-ellipsis whitespace-nowrap mobile:max-w-[35vw] tablet:max-w-[35vw]"
            >
              {errors?.expirationDate?.message}
            </TextField.Helper>
          </div>
          <div className="max-w-[50%]">
            <TextField.Label htmlFor="CVVCode">CVV</TextField.Label>
            <TextField.Root>
              <TextField.Input
                {...registerWithMask(
                  "CVVCode",
                  "999",
                  MASKED_INPUT_DEFAULT_PROPS,
                )}
                id="CVVCode"
                type="text"
                placeholder="000"
                status={handleInputErrorStatus(!!errors?.CVVCode?.message)}
              />
            </TextField.Root>

            <TextField.Helper
              title={errors?.expirationDate?.message}
              className="overflow-hidden text-ellipsis whitespace-nowrap mobile:max-w-[35vw] tablet:max-w-[35vw]"
            >
              {errors?.CVVCode?.message}
            </TextField.Helper>
          </div>
        </div>
        <Button.Root type="submit" className="mt-auto">
          Verificar informações
        </Button.Root>
      </form>
    </div>
  );
};
