import { routes } from "@/utils";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Card, Dialog } from "@hyperlocal/vital2";
import { CardInfoSchema, NewCardFormSchema } from "@newCard/utils";
import { useFormContext } from "react-hook-form";
import { To, useNavigate, useSearchParams } from "react-router-dom";
import { Content } from "./index";

interface Cards {
  label: string;
  icon: IconProps["name"];
  action: () => void;
}

interface CardTypeSelectProps {
  handleContent: (content: Content) => void;
}

interface HandleKeyDown {
  event: React.KeyboardEvent<HTMLDivElement>;
  callback: () => void;
}

export const CardTypeSelect = ({ handleContent }: CardTypeSelectProps) => {
  const { setValue } = useFormContext<NewCardFormSchema>();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const handleKeyDown = ({ event, callback }: HandleKeyDown) => {
    if (event.key === "Enter" || event.key === " ") return callback();
  };

  const onNavigate = (to: To, cardType: CardInfoSchema["type"]) => {
    setValue("cardInfo.type", cardType);
    navigate(to);
  };

  const cards: Cards[] = [
    {
      label: "Físico",
      icon: "GeralCreditCard",
      action: () => handleContent("filledAddress"),
    },
    {
      label: "Temporário",
      icon: "TimeTimer",
      action: () => handleContent("temporaryCardWarning"),
    },
    {
      label: "Virtual",
      icon: "MoneyCard",
      action: () =>
        onNavigate(
          { pathname: routes.newCardNickname, search: id && `id=${id}` },
          "virtual",
        ),
    },
  ];

  return (
    <div className="flex h-[209px] flex-col gap-inset-x2s duration-150 animate-in fade-in">
      <Dialog.Header>
        <Dialog.Title>Novo cartão</Dialog.Title>
      </Dialog.Header>
      {cards.map((card) => (
        <Card
          className="flex w-full cursor-pointer content-between items-center text-neutral-black"
          role="button"
          key={card.label}
          tabIndex={0}
          onClick={card.action}
          onKeyDown={(event) => handleKeyDown({ event, callback: card.action })}
        >
          <div className="flex w-full gap-inset-x2s">
            <Icon
              name={card.icon}
              fill="currentColor"
              className="size-inset-lg"
            />
            <span>{card.label}</span>
          </div>
          <Icon
            name="ArrowNoLineRigh"
            fill="currentColor"
            className="size-inset-lg"
          />
        </Card>
      ))}
    </div>
  );
};
