import cardsMock from "@/tests/mocks/cards.json";
import { CardsResponse } from "@cards/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

const cards = cardsMock.cards as CardsResponse[];

const fetchMockById = (id: string) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const card = cards.find((card) => card.id === id);

      return resolve(card);
    }, 1000);
  });
};

const getCardDetail = async (id: string) => {
  try {
    const response = await fetchMockById(id);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const useCardDetail = (
  id: string,
  options?: UseQueryOptions<CardsResponse>,
) => {
  return useQuery({
    ...options,
    queryKey: ["cardDetail", id],
    queryFn: () => getCardDetail(id),
  });
};
