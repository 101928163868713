import { PayTransferIcon } from "@/assets";
import EmptyListImg from "@/assets/EmptyList.png";
import { ScrollArea } from "@/components";
import { formatCurrency, TODAY } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Card, CardProps } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

const date = new Intl.DateTimeFormat("pt-BR", {
  day: "2-digit",
  month: "long",
}).format(TODAY);

const weekDay = new Intl.DateTimeFormat("pt-BR", {
  weekday: "long",
}).format(TODAY);

interface TransactionItemProps extends CardProps {
  method: "pix" | "bankSlip" | "transfer" | "other";
  type: "income" | "outcome";
}

const getCorrectIcon = (
  method: TransactionItemProps["method"],
  type: TransactionItemProps["type"],
) => {
  const iconNameMap = {
    bankSlip: (
      <Icon name="GeralBarcode" fill="currentColor" className="size-4" />
    ),
    pix: <Icon name="MoneyPix" fill="currentColor" className="size-4" />,
  };

  if (iconNameMap[method]) return iconNameMap[method];

  if (type === "income")
    return (
      <Icon
        name="GeralMoneyPayReceived"
        fill="currentColor"
        className="size-4"
      />
    );

  return <PayTransferIcon />;
};

const TransactionItem = ({ method, type }: TransactionItemProps) => {
  const IconComponent = getCorrectIcon(method, type);

  const value = type === "income" ? 2500 : 2500 * -1;

  return (
    <Card className="flex w-full gap-4">
      <div
        className={twMerge(
          "h-fit w-fit rounded-circle bg-neutral-lighter p-3 text-neutral-black",
          type === "income" &&
            "bg-status-success-light text-status-success-dark",
        )}
      >
        {IconComponent}
      </div>
      <div className="flex flex-col">
        <span className="font-base text-x2s/5 text-neutral-darkest">
          Pagamento Efetuado
        </span>
        <span className="overflow-hidden text-ellipsis whitespace-nowrap font-base text-base text-neutral-dark mobile:max-w-[150px] ">
          DAS Simples Nacional
        </span>
      </div>
      <div className="ml-auto flex flex-col">
        <span className="text-right font-base text-x2s/5 text-neutral-dark">
          11:30
        </span>
        <span
          className={twMerge(
            "font-base text-base text-neutral-darker mobile:text-x2s tablet:text-x2s",
            type === "income" && "text-status-success-dark",
          )}
        >
          {formatCurrency(value)}
        </span>
      </div>
    </Card>
  );
};

export const CardTransactionList = () => {
  const empty = false;

  if (empty) {
    return (
      <div className="flex h-full w-full flex-col p-6 mobile:p-3 tablet:p-4">
        <div className="flex justify-between">
          <h5 className="font-base text-sm/7 font-bold text-neutral-darkest">
            Hoje {date}
          </h5>
          <span className="font-base text-xs/5 text-neutral-dark">
            {weekDay}
          </span>
        </div>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <img src={EmptyListImg} alt="Empty list" width={146} />
          <span className="max-w-[265px] text-center font-base">
            Nenhuma movimentação realizada
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="mb-4 flex justify-between px-6">
        <h5 className="font-base text-sm/7 font-bold text-neutral-darkest">
          Hoje {date}
        </h5>
        <span className="font-base text-xs/5 text-neutral-dark">{weekDay}</span>
      </div>
      <ScrollArea className="h-full">
        <ol className="flex flex-col gap-4 p-6 pt-0 mobile:p-3 tablet:p-4">
          {new Array(25).fill(null).map((_, index) => (
            <TransactionItem
              key={index}
              role="listitem"
              method="bankSlip"
              type="outcome"
            />
          ))}
        </ol>
      </ScrollArea>
    </div>
  );
};
