export const formatMasked = (text: string, mask: string) => {
  const valueArray = text.toString().split("");
  let maskedValue = "";

  for (let i = 0, j = 0; i < mask.length && j < valueArray.length; i++) {
    if (mask[i] === "#") {
      maskedValue += valueArray[j];
      j++;
    } else {
      maskedValue += mask[i];
    }
  }

  return maskedValue;
};

export const MASKED_INPUT_DEFAULT_PROPS = {
  autoUnmask: true,
  placeholder: "",
  showMaskOnFocus: false,
  showMaskOnHover: false,
};
