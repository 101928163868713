import { HyperlocalBigCard } from "@/assets";
import CreditCardChip from "@/assets/CreditCardChip.png";
import { ScrollArea } from "@/components";
import { routes, TOTPActions, useMediaQuery } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import {
  Button,
  Dialog,
  Sheet,
  Stepper,
  StepperExtendedSteps,
} from "@hyperlocal/vital2";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const CancelOrder = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const isMobile = useMediaQuery("mobile");

  return (
    <>
      {!isMobile && (
        <div className="mobile:hidden tablet:hidden">
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button.Root
                fullWidth
                variant="link"
                className="text-status-error-dark hover:text-status-error-dark focus:text-status-error-dark active:text-status-error-dark"
              >
                Cancelar pedido
              </Button.Root>
            </Dialog.Trigger>
            <Dialog.Content className="max-w-[370px]">
              <Dialog.Header>
                <Dialog.Title>Cancelar pedido</Dialog.Title>
              </Dialog.Header>
              <p>
                Tem certeza que deseja cancelar o pedido? Será cancelado a
                entrega e você precisará realizar o pedido novamente.
              </p>
              <Dialog.Footer>
                <Dialog.Close asChild>
                  <Button.Root fullWidth variant="secondary">
                    Não
                  </Button.Root>
                </Dialog.Close>
                <Dialog.Close
                  asChild
                  onClick={() =>
                    navigate(
                      { pathname: routes.newCardConfirmTOTP, search },
                      {
                        state: {
                          TOTPAction: TOTPActions.cancelOrder,
                        },
                      },
                    )
                  }
                >
                  <Button.Root fullWidth variant="primary">
                    Sim
                  </Button.Root>
                </Dialog.Close>
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div className="desktop:hidden">
          <Sheet.Root>
            <Sheet.Trigger asChild>
              <Button.Root
                fullWidth
                variant="link"
                className="text-status-error-dark hover:text-status-error-dark focus:text-status-error-dark active:text-status-error-dark"
              >
                Cancelar pedido
              </Button.Root>
            </Sheet.Trigger>
            <Sheet.Content side="bottom">
              <Sheet.Header>
                <Sheet.Title>Cancelar pedido</Sheet.Title>
              </Sheet.Header>
              <p>
                Tem certeza que deseja cancelar o pedido? Será cancelado a
                entrega e você precisará realizar o pedido novamente.
              </p>
              <Sheet.Footer className="flex flex-col gap-2">
                <Sheet.Close asChild>
                  <Button.Root fullWidth variant="secondary">
                    Não
                  </Button.Root>
                </Sheet.Close>
                <Sheet.Close
                  asChild
                  onClick={() =>
                    navigate(
                      { pathname: routes.newCardConfirmTOTP, search },
                      {
                        state: {
                          TOTPAction: TOTPActions.cancelOrder,
                        },
                      },
                    )
                  }
                >
                  <Button.Root fullWidth variant="primary">
                    Sim
                  </Button.Root>
                </Sheet.Close>
              </Sheet.Footer>
            </Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};

type CardOrderStatus = "order" | "sent" | "inRoute" | "delivered";

type StepperStatus = StepperExtendedSteps<CardOrderStatus>;

type StepperOptions = {
  status: CardOrderStatus;
  label: string;
};

const stepperOptions: StepperOptions[] = [
  {
    status: "order",
    label: "Pedido",
  },
  {
    status: "sent",
    label: "Enviado",
  },
  {
    status: "inRoute",
    label: "Em trânsito",
  },
  {
    status: "delivered",
    label: "Entregue",
  },
];

const styles = tv({
  slots: {
    dl: "flex flex-col gap-1 w-full",
    dt: "font-base font-medium text-xs/4 text-neutral-darkest mobile:text-x2s/[14px] tablet:text-x2s/[14px]",
    dd: "font-base text-base text-neutral-dark mobile:text-x2s tablet:text-x2s whitespace-nowrap text-ellipsis overflow-hidden",
    span: "font-base text-neutral-darkest mobile:text-x2s/[14px] mobile:font-medium tablet:text-x2s/[14px] tablet:font-medium",
    h4: "font-base text-md/9 font-bold text-neutral-darkest mobile:text-sm/5 tablet:text-sm/5",
  },
});

export const CardInRouteDetail = () => {
  const step: StepperStatus = "sent";

  const { search } = useLocation();

  const navigate = useNavigate();

  const { dd, dl, dt, span, h4 } = styles();

  return (
    <div className="flex h-full w-full flex-col">
      <ScrollArea className="h-full">
        <div className="flex h-full flex-col items-center justify-center overflow-hidden p-6">
          <span className={span()}>Rastreamento:</span>
          <h4
            className={twMerge(h4(), "mb-4 flex gap-1 mobile:mt-1 tablet:mt-1")}
          >
            TI815033951BR
            <button>
              <Icon
                name="GeralGeralCopy"
                fill="currentColor"
                className="size-8 text-primary-main mobile:size-6 tablet:size-6"
              />
            </button>
          </h4>
          <div className="relative flex h-fit items-start">
            <HyperlocalBigCard className="opacity-70" />
            <img
              src={CreditCardChip}
              alt="Credit card chip"
              className={twMerge(
                "absolute left-0 right-0 top-6 ml-auto mr-auto",
              )}
            />
            <div className="absolute bottom-0 left-0 right-0 top-0 z-10 m-auto flex h-fit w-fit items-center justify-center rounded-circle bg-neutral-lighter p-3.5">
              <Icon
                name="ShopShoppingCart"
                width={20}
                height={20}
                className="fill-current text-neutral-black"
              />
            </div>
          </div>
          <Stepper.Root<CardOrderStatus>
            activeStep={step}
            className="my-4 w-full"
          >
            {stepperOptions.map((step, index, array) => (
              <Stepper.Step<CardOrderStatus>
                key={step.status}
                stepIdentifier={step.status}
              >
                <Stepper.StepLabel>{step.label}</Stepper.StepLabel>
                <Stepper.StepIndicator />
                {index !== array.length - 1 && <Stepper.StepLine />}
              </Stepper.Step>
            ))}
          </Stepper.Root>
          <span className={span()}>Previsão de entrega</span>
          <h4 className={twMerge(h4(), "mobile:mt-2 tablet:mt-2")}>
            20 de Janeiro
          </h4>
          <hr className="my-4 h-[1px] w-full bg-neutral-light" />
          <h5 className="self-start font-base text-sm/[30px] font-bold text-neutral-darkest mobile:text-base tablet:text-base">
            Endereço de entrega
          </h5>
          <div className="mt-4 grid w-full grid-cols-4 gap-4">
            <dl
              className={twMerge(
                dl(),
                "col-start-1 col-end-5 flex-row items-center justify-between",
              )}
            >
              <dt className={dt()}>Nome do cliente</dt>
              <dd className={dd()}>Paulo S Ferreira Bistro Paris</dd>
            </dl>
            <dl className={twMerge(dl(), "col-start-1 col-end-5")}>
              <dt className={dt()}>Endereço</dt>
              <dd className={dd()}>Rua Bela Cintra Grande Filho </dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>Bairro</dt>
              <dd className={dd()}>Paulista</dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>Cidade</dt>
              <dd className={dd()}>São Paulo</dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>UF</dt>
              <dd className={dd()}>SP</dd>
            </dl>
            <dl className={dl()}>
              <dt className={dt()}>Nº</dt>
              <dd className={dd()}>1141</dd>
            </dl>
            <dl className={twMerge(dl(), "col-start-1 col-end-5")}>
              <dt className={dt()}>Complemento</dt>
              <dd className={dd()}>Minha casa</dd>
            </dl>
          </div>
        </div>
      </ScrollArea>
      <div className="flex flex-col p-6">
        <Button.Root
          fullWidth
          onClick={() =>
            navigate({ pathname: routes.confirmCardDelivery, search })
          }
        >
          Cartão recebido
        </Button.Root>
        <CancelOrder />
      </div>
    </div>
  );
};
