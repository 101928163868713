import { handleWebTransition, routes } from "@/utils";
import { Button, TextField } from "@hyperlocal/vital2";
import { Layout } from "@newCard/components";
import { NewCardFormSchema } from "@newCard/utils";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

export const CardNickname = () => {
  const { handleSubmit, register, formState } =
    useFormContext<NewCardFormSchema>();

  const navigate = useNavigate();

  const { search } = useLocation();

  const errors = formState.errors?.cardInfo?.virtual;

  const onSubmit = () => {
    handleWebTransition(() => {
      navigate(routes.newCardResume, {
        state: { from: routes.newCardNickname },
      });
    });
  };

  const handleInputErrorStatus = (error: boolean): "error" | "success" => {
    if (error) return "error";
  };

  return (
    <Layout.Root>
      <Layout.Link
        to={{ pathname: routes.cards, search: `${search}&tipo=selecionar` }}
      >
        Novo cartão virtual
      </Layout.Link>
      <Layout.Content>
        <form
          className="flex h-full flex-1 flex-col justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <span>
              Escolha um apelido significativo para seu cartão virtual,
              associando-o ao propósito que tem em mente para facilitar sua
              identificação.
            </span>
            <div className="mt-inset-md">
              <TextField.Label htmlFor="nickname">Apelido</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="nickname"
                  {...register("cardInfo.virtual.nickname")}
                  status={handleInputErrorStatus(!!errors?.nickname?.message)}
                />
              </TextField.Root>
              <TextField.Helper>{errors?.nickname?.message}</TextField.Helper>
            </div>
          </div>
          <div className="mt-auto flex">
            <Button.Root
              type="button"
              variant="link"
              fullWidth
              onClick={() => navigate(routes.cards)}
            >
              Cancelar
            </Button.Root>
            <Button.Root type="submit" fullWidth>
              Próxima etapa
            </Button.Root>
          </div>
        </form>
      </Layout.Content>
    </Layout.Root>
  );
};
