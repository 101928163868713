import { HyperlocalBigCard, LockTimer, TrashIcon } from "@/assets";
import CreditCardChip from "@/assets/CreditCardChip.png";
import { handleWebTransition, routes, useTooltipTimer } from "@/utils";
import { CardsResponse } from "@cards/types";
import Icon from "@hyperlocal/vital-icons";
import { Button, Tooltip, TooltipTriggerProps } from "@hyperlocal/vital2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import { BlockCardAction } from "../BlockCardAction";
import { CancelCardAction } from "../CancelCardAction";
import { CardTransactionList } from "../CardTransactionList";
import { UnblockCardAction } from "../UnblockCardAction";

const CopyIcon = (props: TooltipTriggerProps) => {
  const [isOpen, handleClick] = useTooltipTimer();

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isOpen}>
        <Tooltip.Trigger onClick={handleClick} {...props}>
          <Icon
            name="GeralGeralCopy"
            fill="currentColor"
            className="size-4 mobile:size-6 tablet:size-6"
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>Número copiado!</Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const cardStyles = tv({
  variants: {
    type: {
      physical: "fill-primary-lighter",
      virtual: "fill-neutral-lighter",
      temporary: "fill-secondary-lighter",
    },
    blocked: {
      true: "opacity-70",
    },
  },
});

const infoStyles = tv({
  slots: {
    dt: "text-neutral-darkest font-bold text-x2s/default font-base",
    dd: "text-neutral-dark font-base text-x2s/normal",
  },
});

export const GeneralCardDetail = ({ status, type }: CardsResponse) => {
  const blocked = status === "blocked";
  const { dd, dt } = infoStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleChangePin = () => {
    handleWebTransition(() =>
      navigate({
        pathname: routes.cardNewPassword,
        search: searchParams.toString(),
      }),
    );
  };

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex flex-col items-center p-6">
        <div className="flex h-full w-full justify-center gap-0">
          <div className="relative flex h-fit items-start">
            <HyperlocalBigCard className={cardStyles({ blocked, type })} />
            <img
              src={CreditCardChip}
              alt="Credit card chip"
              className={twMerge(
                "absolute left-0 right-0 top-6 ml-auto mr-auto",
                blocked && "opacity-70",
              )}
            />
            {blocked && (
              <div className="absolute bottom-0 left-0 right-0 top-0 z-10 m-auto flex h-fit w-fit items-center justify-center rounded-circle bg-neutral-lighter p-3.5">
                <Icon
                  name="GeralSecurityLock"
                  width={20}
                  height={20}
                  className="fill-current text-neutral-black"
                />
              </div>
            )}
          </div>
          <div
            className={twMerge("z-[1]", blocked && "select-none opacity-70")}
          >
            <dl className="grid h-full grid-cols-2 content-center gap-4">
              <div className="col-span-2 flex flex-col">
                <dt className={dt()}>Nome no cartão</dt>
                <dd className={dd()}>PAULO S FERREIRA BISTRO PARIS</dd>
              </div>
              <div className="col-span-2 flex flex-col">
                <dt className={dt()}>Número</dt>
                <dd className={twMerge(dd(), "flex gap-1")}>
                  XXXX XXXX XXXX 4259
                  <CopyIcon disabled={blocked} />
                </dd>
              </div>
              <div className="flex flex-col">
                <dt className={dt()}>Validade</dt>
                <dd className={dd()}>20/23</dd>
              </div>
              <div className="flex flex-col">
                <dt className={dt()}>CVV</dt>
                <dd className={dd()}>123</dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="mt-4 flex gap-14">
          {type === "physical" && (
            <span className="relative flex flex-col items-center">
              <Button.Root
                variant="secondary"
                className="rounded-full px-3 py-3"
                onClick={handleChangePin}
              >
                <LockTimer />
              </Button.Root>
              <span className="absolute bottom-0 text-nowrap">
                Alterar senha
              </span>
            </span>
          )}
          <span className="relative flex flex-col items-center">
            {blocked ? (
              <>
                <UnblockCardAction />
                <span className="absolute bottom-0">Desbloquear</span>
              </>
            ) : (
              <>
                <BlockCardAction />
                <span className="absolute bottom-0">Bloquear</span>
              </>
            )}
          </span>
          <span className="flex flex-col items-center">
            <CancelCardAction>
              <Button.Root
                variant="secondary"
                className="rounded-full px-3 py-3"
              >
                <TrashIcon />
              </Button.Root>
            </CancelCardAction>
            Cancelar
          </span>
        </div>
      </div>
      <CardTransactionList />
    </div>
  );
};
